/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { GoalState } from 'reducers/goal-slice/type';
import { ValidationErrors } from 'type/error';
import { updateGoalApi } from 'api/goal';
import { FormGoalProps, FetchGoalResponse } from 'api/type';
import { StatusCode } from 'type/error';
import { selectRoleOwner } from '../selector';
import { RootState } from 'reducers/rootReducer';
import { lensPath, set } from 'ramda';
import { first } from 'lodash';
export const updateGoalThunk = createAsyncThunk<
  FetchGoalResponse,
  FormGoalProps & {
    id: string | number;
  },
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('goal/updateGoal', async (option, { getState }) => {
  try {
    const roleOwner = selectRoleOwner(getState())?.data;
    const xRoleOwnerLens = lensPath([0]);
    const firstItemOwner = first(option.data.relationships.owners.data);
    const updatedRoleOwner = set(xRoleOwnerLens, firstItemOwner, roleOwner);

    const xLens = lensPath(['data', 'relationships', 'owners', 'data']);

    const updatedOwnerOption = set(xLens, updatedRoleOwner, option);

    const response = await updateGoalApi(updatedOwnerOption);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const updateGoalReducer = (builder: ActionReducerMapBuilder<GoalState>): void => {
  builder.addCase(updateGoalThunk.fulfilled, (state, { payload }) => {
    state.error = undefined;
    state.goal = {
      ...payload.data,
      attributes: {
        settings: state.goal?.attributes.settings,
        ...payload.data.attributes,
      },
    };
    state.includedDetail = payload.included;
  });
  builder.addCase(updateGoalThunk.pending, state => {
    state.error = undefined;
  });
  builder.addCase(updateGoalThunk.rejected, (state, action) => {
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import { convertObjectToBase64 } from 'helper/common';
import * as criteria from 'helper/criteria';
/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { GoalState } from 'reducers/goal-slice/type';
import { ValidationErrors } from 'type/error';
import { FetchGoalsProps, FetchGoalsResponse } from 'api/type';
import { fetchGoalsApi } from 'api/goal';
import { PAGE_SIZE_NUMBER } from 'config/api';
import { StatusCode } from 'type/error';

import { selectRole } from 'reducers/auth-slice/selector';
import { RootState } from 'reducers/rootReducer';

export const fetchGoalsThunk = createAsyncThunk<
  FetchGoalsResponse,
  FetchGoalsProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('goal/fetchGoals', async (option, { getState }) => {
  try {
    const state: RootState = getState();
    const role = selectRole(state);

    const defaultFilter = criteria.build(
      criteria.operator.AND,
      criteria.build(
        criteria.operator.OR,
        {
          field: 'collaborators',
          operator: criteria.operator.GREATHER_THAN,
          value: 0,
          criteria: {
            field: 'id',
            operator: criteria.operator.EQUAL,
            value: role?.id,
          },
        },
        {
          field: 'role',
          operator: criteria.operator.EQUAL,
          value: role?.id,
        },
      ),
      {
        field: 'status',
        operator: criteria.operator.EQUAL,
        value: 'APPRO',
      },
    );
    let filter = defaultFilter;

    if (option.decodedFilter) {
      filter = criteria.build(criteria.operator.AND, defaultFilter, option.decodedFilter);
    }
    const encodedFilter = convertObjectToBase64(filter);

    const response = await fetchGoalsApi({
      ...option,
      size: PAGE_SIZE_NUMBER,
      filter: encodedFilter,
      include: 'owners.user,aligned_parent_goals_appro.id',
    });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchGoalsReducer = (builder: ActionReducerMapBuilder<GoalState>): void => {
  builder.addCase(fetchGoalsThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.goals = payload.data;
    state.pagination = payload.meta.pagination;
    state.included = payload.included;
  });
  builder.addCase(fetchGoalsThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchGoalsThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import { createSelector } from '@reduxjs/toolkit';
import { Template, CustomAttribute } from 'reducers/template-slice/type';
import { RootState } from 'reducers/rootReducer';
import { Item as ItemDropdown } from 'components/core/dropdown/type';
export const selectTemplates = (state: RootState): Template[] => state.template.templates;
export const selectAttributes = (state: RootState): CustomAttribute[] => state.template.attributesIncluded;

export const selectTemplatesForDropdown = createSelector(
  (state: RootState) => state.template.templates,
  (templates): ItemDropdown<Template>[] =>
    templates.map((template: Template) => ({
      header: template.attributes.name,
      data: template,
    })),
);

export const selectError = (state: RootState): string | undefined | null => state.template.error;

export const selectIsLoading = (state: RootState): boolean => state.template.isLoading;

import { useEffect } from 'react';

function useWindowResize(callback: () => void): void {
  useEffect(() => {
    const handler = () => {
      callback();
    };

    // Set size at the first client-side load
    handler();

    window.addEventListener('resize', handler);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useWindowResize;

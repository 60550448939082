import { createSelector } from '@reduxjs/toolkit';
import { Weight } from 'reducers/weight-slice/type';
import { RootState } from 'reducers/rootReducer';
import { Item as ItemDropdown } from 'components/core/dropdown/type';
export const selectWeights = (state: RootState): Weight[] => state.weight.weights;

export const selectWeightsForDropdown = createSelector(
  (state: RootState) => state.weight.weights,
  (weights): ItemDropdown<Weight>[] =>
    weights.map((weight: Weight) => ({
      header: weight.attributes.name,
      data: weight,
    })),
);

export const selectError = (state: RootState): string | undefined | null => state.weight.error;

export const selectIsLoading = (state: RootState): boolean => state.weight.isLoading;

import React from 'react';
import { Button, ButtonProps } from '@fluentui/react-northstar';
import bn from 'helper/bemnames';
import classNames from 'classnames';
const bem = bn.create('btn');
type ButtonType = 'radius' | '';
type ButtonColor = 'primary' | '';
interface Props extends ButtonProps {
  className?: string;
  buttonType?: ButtonType;
  buttonColor?: ButtonColor;
  type?: 'button' | 'reset' | 'submit';
}
const ButtonComponent: React.FC<Props> = (props: Props) => {
  const { className, buttonType = '', buttonColor = '', type = 'button', ...rest } = props;
  return <Button {...rest} type={type} className={classNames(bem.b([className]), bem.m(buttonType), bem.m(buttonColor))} />;
};
export default ButtonComponent;

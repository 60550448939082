import { apiClient } from './client';
import { FetchTemplatesProps, FetchTemplatesResponse, FetchTemplateResponse, FetchTemplateProps } from './type';

export const fetchTemplatesApi = async ({ keyword, sort, filter, include }: FetchTemplatesProps): Promise<FetchTemplatesResponse> => {
  const url = '/templates';

  const { data } = await apiClient.get(url, {
    params: {
      keyword,
      sort,
      filter,
      include,
      'fields[entityTemplate]': 'name,description,is_archived,is_enabled,settings,entityTemplateAttributes,entity_type',
    },
  });
  return data;
};

export const fetchTemplateApi = async ({ id }: FetchTemplateProps): Promise<FetchTemplateResponse> => {
  const url = `/templates/${id}`;

  const { data } = await apiClient.get<FetchTemplateResponse>(url);
  return data;
};

export default { fetchTemplatesApi, fetchTemplateApi };

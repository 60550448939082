/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { GoalState } from 'reducers/goal-slice/type';
import { ValidationErrors } from 'type/error';
import { createGoalApi } from 'api/goal';
import { FormGoalProps, FetchGoalResponse } from 'api/type';
import { StatusCode } from 'type/error';

export const createGoalThunk = createAsyncThunk<
  FetchGoalResponse,
  FormGoalProps,
  {
    rejectValue: ValidationErrors;
  }
>('goal/createGoal', async option => {
  try {
    const response = await createGoalApi(option);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const createGoalReducer = (builder: ActionReducerMapBuilder<GoalState>): void => {
  builder.addCase(createGoalThunk.fulfilled, (state, { payload }) => {
    state.error = undefined;
    state.goal = payload.data;
    state.includedDetail = payload.included;
  });
  builder.addCase(createGoalThunk.pending, state => {
    state.error = undefined;
  });
  builder.addCase(createGoalThunk.rejected, (state, action) => {
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import React from 'react';
import { update } from 'reducers/update-slice/type';

interface FileInput {
  onChangeHandler: (e: React.ChangeEvent | string, reference: string | undefined, appearance?: string | undefined) => void;
  fileUploaded: FileList | null;
  editingItem: update | null;
}

const FileInput: React.FC<FileInput> = props => {
  return (
    <>
      <label className='custom-file-uploader'>
        {props.editingItem?.relationships.file.data ? 'Replace Attached File' : 'Attach New File'}
        <input type='file' onChange={e => props.onChangeHandler(e, 'file')} />
      </label>
      <span>{props.fileUploaded ? `${props.fileUploaded[0].name}` : ''}</span>
    </>
  );
};

export default FileInput;

/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// components
import GoalModal, { GoalModalProps } from 'components/modals/goal/Goal';
import { ActionMode, GoalProps, GoalFormProps } from 'components/forms/goal/type';
import { Item as DropdownItem } from 'components/core/dropdown/type';
// reducers
import { FormGoalProps } from 'api/type';
import { Goal } from 'reducers/goal-slice/type';
import { updateGoalThunk } from 'reducers/goal-slice/reducers/updateGoalReducer';
import { createGoalThunk } from 'reducers/goal-slice/reducers/createGoalReducer';
// import { fetchGoalsThunk } from 'reducers/goal-slice/reducers/fetchGoalsReducer';
import { selectGoal } from 'reducers/goal-slice/selector';
import { AppDispatch, useAppDispatch, useAppSelector } from 'reducers/store';
import { selectInitialValuesForm } from 'reducers/goal-slice/selector';
import { segmentAnalyticsThunk } from 'reducers/auth-slice/reducers/segmentAnalyticsReducer';

import { selectUser } from 'reducers/auth-slice/selector';
import { Template } from 'reducers/template-slice/type';
import { fetchTemplatesThunk } from 'reducers/template-slice/reducers/fetchTemplatesReducer';
import { selectTemplatesForDropdown } from 'reducers/template-slice/selector';

import { fetchWeightsThunk } from 'reducers/weight-slice/reducers/fetchWeightsReducer';
import { selectWeightsForDropdown } from 'reducers/weight-slice/selector';
import { Weight } from 'reducers/weight-slice/type';

import { fetchUsersThunk } from 'reducers/user-slice/reducers/fetchUsersReducer';
import { selectUsersForDropdown } from 'reducers/user-slice/selector';
import { User } from 'reducers/user-slice/type';

import { fetchConfigThunk } from 'reducers/config-slice/reducers/fetchConfigReducer';
import { Config } from 'reducers/config-slice/type';
import { selectConfig } from 'reducers/config-slice/selector';

import { fetchPlansThunk } from 'reducers/plan-slice/reducers/fetchPlansReducer';
import { fetchGoalsByPlanThunk } from 'reducers/planner-slice/reducers/fetchGoalsByPlanReducer';
import { selectPlansForDropdown, selectGoalsByPlanForDropdown } from 'reducers/plan-slice/selector';
import { Plan } from 'reducers/plan-slice/type';
import * as microsoftTeams from '@microsoft/teams-js';
// other
import bn from 'helper/bemnames';
import { SEGMENT_INTEGRATION_ID } from 'config/environment';
import { filter } from 'helper/criteria';

const bem = bn.create('goal-create');
interface Props {
  onCloseModal?: () => void;
  modalProps: GoalModalProps;
  formProps: Pick<GoalProps, 'action' | 'goal'>;
  desc?: string;
  isFromBot?: boolean;
  sort?: string;
  join?: string;
  decodedFilter?: filter | null;
  showGoals?: boolean;
  planId?: string;
}

// eslint-disable-next-line max-lines-per-function
const GoalFormScreen: React.FC<Props> = ({ modalProps, formProps, isFromBot = false, desc, showGoals = false, planId = '' }: Props) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false);
  const instanceName = useAppSelector<string>(state => state.auth.instanceName);
  const user = useAppSelector<User | undefined>(selectUser);
  const goal = useAppSelector<Goal | undefined>(selectGoal);

  const templatesOptions = useAppSelector<DropdownItem<Template>[]>(selectTemplatesForDropdown);
  const weightsOptions = useAppSelector<DropdownItem<Weight>[]>(selectWeightsForDropdown);
  const config = useAppSelector<Config[]>(selectConfig);
  const usersOptions = useAppSelector<DropdownItem<User>[]>(selectUsersForDropdown);
  const plansOptions = useAppSelector<DropdownItem<Plan>[]>(selectPlansForDropdown);
  const goalsByPlanOptions = useAppSelector<DropdownItem<Goal>[]>(selectGoalsByPlanForDropdown);

  useEffect(() => {
    dispatch(fetchTemplatesThunk({}));
    dispatch(fetchWeightsThunk({}));
    dispatch(fetchConfigThunk({}));
    dispatch(fetchUsersThunk({}));
    dispatch(
      fetchPlansThunk({
        include: 'focusAreas',
      }),
    );
  }, [dispatch]);
  const fetchGoalsByPlan = ({ id }: { id: string | number; keyword?: string }, callback?: (data: Goal[]) => void) => {
    dispatch(fetchGoalsByPlanThunk({ id, sort: 'title' }))
      .then(unwrapResult)
      .then(response => {
        if (callback) callback(response.data);
      });
  };

  const fetchUsers = () => {
    dispatch(fetchUsersThunk({}));
  };
  const fetchPlans = () => {
    dispatch(fetchPlansThunk({ include: 'focusAreas' }));
  };

  const onSubmit = (values: FormGoalProps, callback?: () => void) => {
    if (formProps.action === ActionMode.Create) {
      dispatch(createGoalThunk(values))
        .then(unwrapResult)
        .then(({ data, included }) => {
          dispatch(
            segmentAnalyticsThunk({
              event: 'Goal Created',
              options: {
                goal_id: data.id,
                page_name: 'createGoal',
                source_invoker: SEGMENT_INTEGRATION_ID,
                source_input: 'integration',
                tags: values.data.relationships.aligned_parent_goals_appro?.data,
                owner_id:
                  values.data.relationships.owners.data && values.data.relationships.owners.data[0]
                    ? values.data.relationships.owners.data[0].id
                    : '',
                goal_state: 'active',
                goal_template_id: values.data.relationships.entity_template.data.id,
                creator_id:
                  values.data.relationships.owners.data && values.data.relationships.owners.data[0]
                    ? values.data.relationships.owners.data[0].id
                    : '',
              },
            }),
          );
          if (!isFromBot) {
            setIsShowConfirmation(true);
            if (callback) callback();
          }
          if (isFromBot) {
            microsoftTeams.dialog.url.submit({
              data: {
                goal: data,
                included: included,
              },
              isSuccess: true,
            });
          }
          if (showGoals) {
            dispatch(fetchGoalsByPlanThunk({ id: planId, sort: 'title' }));
          }
        })
        .catch(error => {
          if (isFromBot) {
            microsoftTeams.dialog.url.submit({ error, isSuccess: false });
          }
        });
    } else if (goal) {
      dispatch(
        updateGoalThunk({
          ...values,
          id: goal.id,
        }),
      )
        .then(unwrapResult)
        .finally(() => {
          if (callback) callback();
          dispatch(
            segmentAnalyticsThunk({
              event: 'Goal Updated',
              options: {
                source_invoker: SEGMENT_INTEGRATION_ID,
              },
            }),
          );
          if (showGoals) {
            dispatch(fetchGoalsByPlanThunk({ id: planId, sort: 'title' }));
          }
        });
    }
  };
  const getInitialValues = (): GoalFormProps => {
    const defaultTemplate = templatesOptions[0];
    const defaultWeight = weightsOptions[0];

    const initialValues = selectInitialValuesForm({
      goal,
      isCreate: formProps.action === ActionMode.Create,
      desc,
      user,
      defaultTemplate,
      defaultWeight,
      usersOptions,
    });
    return initialValues;
  };
  const initialValues = getInitialValues();

  return (
    <div className={bem.b()}>
      <GoalModal
        modalProps={{
          ...modalProps,
          isShowConfirmation,
          setIsShowConfirmation,
        }}
        formProps={{
          ...formProps,
          goal,
          desc,
          user,
          instanceName,
          config,
          templatesOptions,
          weightsOptions,
          usersOptions,
          plansOptions,
          goalsByPlanOptions,
          fetchGoalsByPlan,
          fetchUsers,
          fetchPlans,
          onSubmit,
          initialValues,
        }}
      />
    </div>
  );
};
GoalFormScreen.defaultProps = {
  modalProps: {
    open: true,
    backdrop: false,
    className: bem.e('goal-modal'),
    isShowConfirmation: false,
  },
  formProps: {
    action: ActionMode.Create,
  },
};
export default GoalFormScreen;

import { FetchConfigProps } from './../../../api/type';
/* eslint-disable no-param-reassign */
import { ConfigState } from './../type';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ValidationErrors } from 'type/error';
import { FetchConfigResponse } from 'api/type';
import { StatusCode } from 'type/error';
import { fetchConfigApi } from 'api/config';
export const fetchConfigThunk = createAsyncThunk<
  FetchConfigResponse,
  FetchConfigProps,
  {
    rejectValue: ValidationErrors;
  }
>('config/fetchConfig', async option => {
  try {
    const response = await fetchConfigApi({ ...option });
    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchConfigReducer = (builder: ActionReducerMapBuilder<ConfigState>): void => {
  builder.addCase(fetchConfigThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.configs = payload.data;
    state.pagination = payload.meta.pagination;
  });
  builder.addCase(fetchConfigThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchConfigThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

/* eslint-disable max-lines-per-function */

import React from 'react';
import { useState } from 'react';
import { Attributes, update } from 'reducers/update-slice/type';
import { AppDispatch, useAppDispatch, useAppSelector } from 'reducers/store';
import { selectUser } from 'reducers/auth-slice/selector';
import { User } from 'reducers/user-slice/type';
import { CustomAttribute, CustomTemplate } from 'reducers/template-slice/type';
import { selectAttributes } from 'reducers/template-slice/selector';
import { useEffect } from 'react';
import { buildElement, buildDescriptionBox, getInputs, filterRequiredCustomAtts, validateCustomAttribute } from 'helper/feedinputs';
import { buildNewUpdate, getInitialCustomAttributes, getNewUpdateAttributes } from 'helper/feed';
import { postNewUpdate, putUpdate } from 'helper/feeddispatches';
import Buttons from 'components/forms/update/Buttons';
import FileInput from 'components/forms/update/FileInput';
import { dispatchSegmentEvent, SegmentEvent } from 'helper/segment';
interface FeedInputProps {
  onCancel: () => void;
  onAddItem?: (comment: string) => void;
  onEditItem?: (comment: string) => void;
  onDeleteItem?: () => void;
  goalId: string;
  selectedUpdate: CustomTemplate;
  editingItem: update | null;
}

const FeedInput: React.FC<FeedInputProps> = props => {
  const attributes = useAppSelector(selectAttributes);
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector<User | undefined>(selectUser);
  const [hasDescriptionField, setHasDescriptionField] = useState(true);
  const [hasFileUpload, setHasFileUpload] = useState(true);
  const [fileUploaded, setFileUploaded] = useState<FileList | null>(null);
  const [inputs, setInputs] = useState<CustomAttribute[]>();
  const [customAttributesValidity, setCustomAttributesValidity] = useState<{ [key: string]: boolean } | void>();
  const [newUpdate, setNewUpdate] = useState<Attributes>({
    comment: '',
    created_at: null,
    custom_attributes: {},
    deleted: false,
    entity_template_id: props.selectedUpdate.id,
    file_id: props.editingItem?.relationships.file.data ? props.editingItem?.relationships.file.data.id : null,
    goal_id: props.goalId && parseInt(props.goalId),
    type: 'UPDATE',
    updated_at: null,
    user_id: user?.id,
  });

  useEffect(() => {
    if (props.selectedUpdate.attributes.settings?.disabledStaticFields.includes('description')) {
      setHasDescriptionField(false);
    }
    if (props.selectedUpdate.attributes.settings?.disabledStaticFields.includes('uploadFile')) {
      setHasFileUpload(false);
    }
    const inputsData = getInputs(props.selectedUpdate, attributes);
    const initialCustomAttributes = props.editingItem
      ? (props.editingItem.attributes.custom_attributes as { [key: string]: string | string[] })
      : getInitialCustomAttributes(inputsData);
    setCustomAttributesValidity(filterRequiredCustomAtts(initialCustomAttributes, inputsData));
    setNewUpdate(getNewUpdateAttributes(props.editingItem, newUpdate, initialCustomAttributes));
    setInputs(inputsData);
  }, []);
  const updateUpdate = () => {
    props.editingItem &&
      putUpdate(dispatch, newUpdate, props.goalId, props.onCancel, props.editingItem, fileUploaded ? fileUploaded[0] : null);
    dispatchSegmentEvent(SegmentEvent.UpdateUpdated, dispatch);
  };
  const createUpdate = () => {
    postNewUpdate(dispatch, newUpdate, props.goalId, props.onCancel, fileUploaded ? fileUploaded[0] : null);
    dispatchSegmentEvent(SegmentEvent.UpdateCreated, dispatch);
  };

  const onPostHandler = () => {
    isFormValid() ? (props.editingItem ? updateUpdate() : createUpdate()) : null;
  };

  const isFormValid = () => {
    if (customAttributesValidity) {
      return Object.values(customAttributesValidity as { [key: string]: boolean }).every(att => att === true);
    }
  };

  const onChangeHandler = (e: React.ChangeEvent | string, reference: string | undefined, appearance?: string) => {
    let updatedState = { ...newUpdate };
    if (reference == 'file') {
      const target = (e as React.ChangeEvent).target as HTMLInputElement;
      setFileUploaded(target.files);
      return;
    }
    if (reference == 'comment') {
      setNewUpdate({
        ...newUpdate,
        comment: e as string,
      });
      return;
    }
    if (updatedState.custom_attributes && reference) {
      updatedState = buildNewUpdate(updatedState, reference, e, appearance as string);
    }
    setNewUpdate({
      ...newUpdate,
      custom_attributes: updatedState.custom_attributes,
    });
    if (customAttributesValidity && (reference as string) in customAttributesValidity) {
      setCustomAttributesValidity(validateCustomAttribute(reference as string, customAttributesValidity, updatedState));
    }
  };

  return (
    <>
      <p>
        {props.editingItem ? 'Edit' : 'Add'} {props.selectedUpdate.attributes.name}
      </p>
      <div className='dynamic-inputs'>
        {hasDescriptionField ? buildDescriptionBox(onChangeHandler, newUpdate) : null}
        {hasFileUpload ? <FileInput onChangeHandler={onChangeHandler} fileUploaded={fileUploaded} editingItem={props.editingItem} /> : null}
        {inputs ? inputs.map(inp => buildElement(inp, onChangeHandler, newUpdate)) : null}
        <Buttons
          editingItem={props.editingItem}
          goalId={props.goalId}
          onCancel={props.onCancel}
          dispatch={dispatch}
          isFormValid={isFormValid}
          onPostHandler={onPostHandler}
        />
      </div>
    </>
  );
};

export default FeedInput;

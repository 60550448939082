export enum operator {
  AND = 'and',
  OR = 'or',
  GREATHER_THAN = '>',
  GREATHER_THAN_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_THAN_EQUAL = '<=',
  EQUAL = '=',
  NOT_EQUAL = '!=',
}
type criteria = {
  operator: operator;
  field: string;
  value: number | string | undefined;
};
export type filter = {
  operator: operator;
  value: number | string | Array<filter> | undefined;
  field?: string;
  criteria?: criteria;
};

export const build = (operator: operator | null, left: filter, right: filter | null): filter => {
  if (operator == null) {
    return left;
  } else {
    const pRight = right as filter;
    const filter: filter = { operator: operator, value: [left, pRight] };
    return filter;
  }
};

//import { DropdownProps } from '@fluentui/react-northstar';

import React from 'react';
import { DropdownProps } from '@fluentui/react-northstar';
import Dropdown from '../dropdown/Dropdown';
import { Item as DropdownItem } from 'components/core/dropdown/type';
import moment, { unitOfTime } from 'moment';
export enum dueValues {
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  THIS_QUARTER,
}

export const getDueDateFilterRange = (value: DropdownItem<dueValues>): [string, string] | null => {
  const currentDate = moment();

  let unit: unitOfTime.Base | unitOfTime._quarter | null = null;

  switch (value.data) {
    case dueValues.TODAY:
      unit = 'day';
      break;
    case dueValues.THIS_QUARTER:
      unit = 'quarter';
      break;
    case dueValues.THIS_WEEK:
      unit = 'week';
      break;
    case dueValues.THIS_YEAR:
      unit = 'year';
      break;
    case dueValues.THIS_MONTH:
      unit = 'month';
      break;
    default:
      unit = null;

      break;
  }

  if (unit) {
    const fromDateTimeUtc = currentDate.startOf(unit).toISOString();
    const toDateTimeUtc = currentDate.endOf(unit).toISOString();
    return [fromDateTimeUtc, toDateTimeUtc];
  }
  return null;
};

const inputItems: DropdownItem<dueValues>[] = [
  {
    header: 'Due Today',
    data: dueValues.TODAY,
  },
  {
    header: 'Due this Week',
    data: dueValues.THIS_WEEK,
  },
  {
    header: 'Due this Month',
    data: dueValues.THIS_MONTH,
  },
  {
    header: 'Due this Quarter',
    data: dueValues.THIS_QUARTER,
  },
  {
    header: 'Due this Year',
    data: dueValues.THIS_YEAR,
  },
];

type Props = DropdownProps;
const DueFilterDropDown: React.FC<Props> = (props: DropdownProps) => {
  return <Dropdown items={inputItems} {...props} placeholder='Due Date' clearable={props.value ? true : undefined}></Dropdown>;
};

export default DueFilterDropDown;

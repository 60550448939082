import { Plan } from 'reducers/plan-slice/type';
import { RootState } from 'reducers/rootReducer';
import { flatten } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { Goal, Included } from 'reducers/goal-slice/type';
// helpers
const getIncludeData = (data: Included, included: Included[]): Included[] | Included => {
  const includedItems = included.filter(item => item.id === data.id && item.type === data.type);
  if (includedItems.length === 0) {
    return [];
  }
  const includedItem = includedItems[0];
  const relationships = includedItem.relationships;
  if (!relationships) {
    return includedItem;
  }

  const relationship = relationships.user;
  if (!relationship || !relationship.data) {
    return [];
  }

  return included.filter(item => item.id === relationship.data?.id && item.type === relationship.data.type);
};

export const selectPlans = (state: RootState): Plan[] => state.planner.plans;
// export const selectGoalsByPlan = (state: RootState): FetchGoalsResponse | undefined => state.planner.goalsByPlan;

export const selectError = (state: RootState): string | undefined | null => state.planner.error;

export const selectIsLoading = (state: RootState): boolean => state.planner.isLoading;

export const selectGoalsByPlan = createSelector(
  (state: RootState) => state.planner.goalsByPlan,
  goalsByPlan => {
    const goals = goalsByPlan?.data.map((goal: Goal) => {
      const weightData = goal.relationships.weight.data
        ? goalsByPlan.included.find(
            (item: { type: string | undefined; id: string | number | undefined }) =>
              item.type === goal.relationships.weight.data?.type && item.id === goal.relationships.weight.data?.id,
          )
        : undefined;
      const entityTemplateData = goal.relationships.entityTemplate.data
        ? goalsByPlan.included.find(
            (item: { type: string | undefined; id: string | number | undefined }) =>
              item.type === goal.relationships.entityTemplate.data?.type && item.id === goal.relationships.entityTemplate.data?.id,
          )
        : undefined;
      const unitData = goal.relationships.unit.data
        ? goalsByPlan.included.find(
            (item: { type: string | undefined; id: string | number | undefined }) =>
              item.type === goal.relationships.unit.data?.type && item.id === goal.relationships.unit.data?.id,
          )
        : undefined;

      const ownersData = flatten(
        goal.relationships?.owners?.data?.map(item => {
          return getIncludeData(item, goalsByPlan.included);
        }),
      );

      const updatesData = flatten(
        goal.relationships?.updates?.data?.map(item => {
          return getIncludeData(item, goalsByPlan.included);
        }),
      );

      return {
        ...goal,
        relationships: {
          ...goal.relationships,
          weight: {
            data: weightData,
          },
          entityTemplate: {
            data: entityTemplateData,
          },
          unit: {
            data: unitData,
          },
          owners: {
            data: ownersData,
          },
          updates: {
            data: updatesData,
          },
        },
      };
    });
    return { ...goalsByPlan, data: goals };
  },
);

import { FetchFileProps, FetchFileResponse } from 'reducers/file-slice/type';
import { apiClient } from './client';
import axios from 'axios';

export const fetchFileApi = async ({ id }: FetchFileProps): Promise<FetchFileResponse> => {
  const urllink = `/file-download-link/${id}`;
  const { data } = await apiClient.get<FetchFileResponse>(urllink);
  return data;
};

export const postFileApi = async (params: { file: File; goalId: string }): Promise<FetchFileResponse> => {
  const url = `/files`;

  const { data } = await apiClient.post<FetchFileResponse>(url, {
    data: { attributes: { filename: params.file.name, goal_id: params.goalId } },
  });
  return data;
};

export const patchFileApi = async (
  params: { file: File; goalId: string },
  version_id: string,
  fileId: number,
): Promise<FetchFileResponse> => {
  const url = `/files/${fileId}`;

  const { data } = await apiClient.patch<FetchFileResponse>(url, {
    data: { attributes: { filename: params.file.name, is_valid: 1, version_id: version_id } },
  });
  return data;
};

export const getAwsUrl = async (fileId: number): Promise<string> => {
  const url = `/file-upload-link/${fileId}`;

  const { data } = await apiClient.get(url);

  return data;
};

export const uploadImage = async (awsUrl: string, file: File): Promise<string> => {
  const response = await axios.put(awsUrl, file, {
    headers: {
      'Content-type': 'application/octet-stream',
    },
  });
  return response.headers['x-amz-version-id'];
};

export const deleteFileApi = async (fileId: { id: string | number }): Promise<FetchFileResponse> => {
  const url = `/files/${fileId.id}`;

  const { data } = await apiClient.delete(url);

  return data;
};

export default { fetchFileApi };

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { SetStateAction, useEffect, useState } from 'react';
import bn from 'helper/bemnames';
import { unwrapResult } from '@reduxjs/toolkit';
import { FetchGoalResponse, FetchGoalsResponse } from 'api/type';
import { Table } from '@fluentui/react-northstar';
import { Goal, IncludedAttribute } from 'reducers/goal-slice/type';
import GoalHeader from 'components/goals/goal-rows/GoalHeader';
import GoalRows from 'components/goals/goal-rows/GoalRows';
import { AppDispatch, useAppDispatch, useAppSelector } from 'reducers/store';
import { fetchGoalThunk } from 'reducers/goal-slice/reducers/fetchGoalReducer';
import { segmentAnalyticsThunk } from 'reducers/auth-slice/reducers/segmentAnalyticsReducer';
import { SEGMENT_INTEGRATION_ID } from 'config/environment';
import { ActionMode } from 'components/forms/goal/type';
import { selectGoalsByPlan } from 'reducers/planner-slice/selector';
import FocusArea from './FocusArea';
import { fetchGoalApi } from 'api/goal';
import Loader from 'components/core/loader/Loader';

interface Props {
  setIsOpenFormModal: (value: boolean) => void;
  setFormAction: (mode: SetStateAction<ActionMode>) => void;
  handleSort: (field: string, join: string | null | undefined) => void;
  sort: string;
}

interface FocusAreaWithGoals {
  id: string | number;
  type: string;
  attributes?: IncludedAttribute | undefined;
  goals: Goal[];
}

const bem = bn.create('goals-list');

const GoalsList: React.FC<Props> = ({ setIsOpenFormModal, setFormAction, handleSort, sort }: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [goals, setGoals] = useState<Goal[]>([]);
  const [focusAreas, setFocusAreas] = useState<FocusAreaWithGoals[]>([]);
  const [focusAreasWithGoals, setFocusAreasWithGoals] = useState<FocusAreaWithGoals[]>([]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const goalsByPlan = useAppSelector<FetchGoalsResponse | undefined>(selectGoalsByPlan);
  const [loadingGoals, setLoadingGoals] = useState(false);
  const [noFocusAreas, setNoFocusAreas] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    goalsByPlan && setGoals(goalsByPlan.data);
    if (goalsByPlan && goalsByPlan.included) {
      setLoadingGoals(true);
      const fa = goalsByPlan.included
        .filter(include => include.type === 'focusArea')
        .map(include => {
          return { id: include.id, type: 'focusArea', attributes: include.attributes, goals: [] };
        });
      fa.length === 0 ? setNoFocusAreas(true) : setFocusAreas(fa);
    }
  }, [goalsByPlan, dispatch]);

  useEffect(() => {
    if (focusAreas.length > 0 && goals.length > 0) {
      const focusAreasCopy = focusAreas;
      const promises = goals.map(async goal => {
        return asignFocusArea(goal, goal);
      });
      if (promises.length > 0) {
        Promise.allSettled(promises).then(response => {
          response.forEach(r => {
            if (r.status === 'fulfilled') {
              // TODO unknown type error
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (r.value.index && focusAreasCopy[Number(r.value.index)]) {
                // insert goal in corresponding focus area
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                focusAreasCopy[Number(r.value.index)].goals = [...focusAreasCopy[Number(r.value.index)].goals, r.value.goal];
              }
            }
          });
          const sortedFA = focusAreasCopy.sort((a, b) => {
            if (a && b && a.attributes && a.attributes.name && b.attributes && b.attributes.name) {
              if (a.attributes.name < b.attributes.name) {
                return -1;
              }
              if (a.attributes.name > b.attributes.name) {
                return 1;
              }
            }
            return 0;
          });
          setFocusAreasWithGoals(sortedFA);
          setLoadingGoals(false);
        });
      }
    }
    if (noFocusAreas) setLoadingGoals(false);
  }, [focusAreas, goals, noFocusAreas]);

  // asign index in focusArea state to each goal
  // we need to pass originalGoal parameter to keep track of the goal that was being asigned in the first place because it's a recursive
  // function that calls itself with parent of original goal until it finds one with a focus area as parent
  const asignFocusArea = async (originalGoal: Goal, goal: Goal): Promise<unknown> => {
    if (
      goal.relationships.aligned_focus_areas &&
      goal.relationships.aligned_focus_areas.data &&
      goal.relationships.aligned_focus_areas.data.length > 0
    ) {
      const id = goal.relationships.aligned_focus_areas.data[0]?.id;
      const index = focusAreas.findIndex(fa => fa.id === id);
      return { goal: originalGoal, index: index.toString() };
    } else if (
      goal.relationships.aligned_parent_goals_appro &&
      goal.relationships.aligned_parent_goals_appro.data &&
      goal.relationships.aligned_parent_goals_appro.data.length > 0
    ) {
      const id = goal.relationships.aligned_parent_goals_appro.data[0].id;

      const parentGoal = goals?.find(g => g.id === id);
      if (parentGoal) {
        return await asignFocusArea(originalGoal, parentGoal);
      } else {
        // fetch parent goal since it is not in the list retrieved by goalsByPlan response
        const parentGoalResponse = await fetchParentGoal(id);
        if (parentGoalResponse) {
          return await asignFocusArea(originalGoal, parentGoalResponse.data);
        }
      }
    }
    return '-1';
  };

  const fetchParentGoal = async (id: string | number): Promise<FetchGoalResponse> => {
    return await fetchGoalApi({ id });
  };

  const fetchGoal = (id: string | number) =>
    dispatch(fetchGoalThunk({ id }))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          segmentAnalyticsThunk({
            event: 'Goal Viewed',
            options: {
              goal_id: id,
              page_name: 'viewGoal',
              source_invoker: SEGMENT_INTEGRATION_ID,
            },
          }),
        );
        setIsOpenFormModal(true);
      });

  return (
    <div className={bem.b()}>
      {!loadingGoals ? (
        focusAreasWithGoals && focusAreasWithGoals.length > 0 ? (
          focusAreasWithGoals.map(fa => (
            <div key={fa.id}>
              <FocusArea
                title={fa.attributes?.name}
                content={
                  <Table
                    header={GoalHeader({ handleSort, sort })}
                    rows={GoalRows({ goals: fa.goals, fetchGoal, setFormAction })}
                    aria-label='My Goals'
                  />
                }
              />
            </div>
          ))
        ) : (
          <p>No Goals to show</p>
        )
      ) : (
        <Loader loading={true} fluid label='Loading your goals...' />
      )}
    </div>
  );
};

export default GoalsList;

import { fetchConfigReducer } from './reducers/fetchConfigReducer';
import { createSlice } from '@reduxjs/toolkit';

import { ConfigState } from './type';
const initialState: ConfigState = { configs: [], isLoading: false };

const { reducer, actions } = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchConfigReducer(builder);
  },
});
export { reducer, actions as configAction };

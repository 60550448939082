import { useEffect } from 'react';
import { AppDispatch, useAppDispatch } from 'reducers/store';
import { authAction } from 'reducers/auth-slice';
import { TokenState } from 'reducers/auth-slice/type';

import { Base64 } from 'js-base64';
import { useQuery } from './useQuery';
import { fetchProfileThunk } from 'reducers/auth-slice/reducers/fetchProfileReducer';

export function useParamsUpdateTokenState(): void {
  const dispatch: AppDispatch = useAppDispatch();
  const query: URLSearchParams = useQuery();
  const tokenKey = query.get('key');
  useEffect(() => {
    if (!tokenKey) {
      return;
    }
    const tokenObject: TokenState | undefined = JSON.parse(Base64.decode(tokenKey));
    if (tokenObject && tokenObject.instance && tokenObject.instanceRegion) {
      dispatch(authAction.signInUser(tokenObject));
      dispatch(
        authAction.companyUser({
          instanceName: tokenObject.instance,
          instanceRegion: tokenObject.instanceRegion,
          leadId: tokenObject.leadId,
        }),
      );
      dispatch(fetchProfileThunk());
    }
  }, [tokenKey, dispatch]);
}

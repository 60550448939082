import { apiClient } from './client';
import { FetchWeightsProps, FetchWeightsResponse, FetchWeightResponse, FetchWeightProps } from './type';

export const fetchWeightsApi = async ({ keyword, sort, filter, include }: FetchWeightsProps): Promise<FetchWeightsResponse> => {
  const url = '/weights';

  const { data } = await apiClient.get(url, { params: { keyword, sort, filter, include } });
  return data;
};

export const fetchWeightApi = async ({ id }: FetchWeightProps): Promise<FetchWeightResponse> => {
  const url = `/weights/${id}`;

  const { data } = await apiClient.get<FetchWeightResponse>(url);
  return data;
};

export default { fetchWeightsApi, fetchWeightApi };

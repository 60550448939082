import React from 'react';
import bn from 'helper/bemnames';
import PageTitle from 'components/page-title/PageTitle';
import { AddIcon, Flex } from '@fluentui/react-northstar';
import Button from 'components/core/button/Button';
import { AppDispatch, useAppDispatch } from 'reducers/store';
import { segmentAnalyticsThunk } from 'reducers/auth-slice/reducers/segmentAnalyticsReducer';
import { authAction } from 'reducers/auth-slice';

const bem = bn.create('top-bar');

interface Props {
  title: string;
  addGoalHandler: () => void;
  handleGoBack: () => void;
  showGoals: boolean;
}

const TopBar: React.FC<Props> = ({ title, addGoalHandler, handleGoBack, showGoals }) => {
  const dispatch: AppDispatch = useAppDispatch();

  return (
    <>
      <div className={bem.e('page-header')}>
        {showGoals && (
          <Button
            className={bem.e('go-back-btn')}
            buttonType='radius'
            content='Back to Plans'
            iconPosition={'before'}
            secondary
            size='small'
            onClick={handleGoBack}
          />
        )}
        <PageTitle title={title} />
        <Flex gap='gap.small'>
          <Button
            buttonType='radius'
            icon={<AddIcon size='small' />}
            content='Add Goal'
            iconPosition={'before'}
            primary
            size='small'
            buttonColor='primary'
            className={bem.e('button-add')}
            onClick={addGoalHandler}
          />
          <Button
            buttonType='radius'
            className={bem.e('btn-sign-out')}
            onClick={() => {
              dispatch(
                segmentAnalyticsThunk({
                  event: 'User Logout',
                  options: {},
                  isSystem: true,
                }),
              );
              dispatch(authAction.signOutUser(undefined));
            }}
            content='Sign out'
          ></Button>
        </Flex>
      </div>
    </>
  );
};

export default TopBar;

import { Layout } from '@fluentui/react-northstar';
import React from 'react';
import { useAppSelector } from 'reducers/store';
import { selectIsSystemError } from 'reducers/root-slice/selector';
import SystemError from 'components/system-error/SystemError';
import bn from 'helper/bemnames';
const bem = bn.create('layout');
interface Props {
  children: React.ReactNode | JSX.Element;
}
const LayoutBase: React.FC<Props> = ({ children }: Props) => {
  const isSystemError = useAppSelector<boolean>(selectIsSystemError);
  return (
    <Layout data-testid='layout' className={bem.b()} vertical start={null} main={isSystemError ? <SystemError /> : children} end={null} />
  );
};

export default LayoutBase;

import React, { useState } from 'react';
import BaseModal, { BaseModalProps } from 'components/modals/base-modal/Base';
import GoalForm from 'components/forms/goal/Goal';
import Feed from 'components/feed/Feed';
import { ActionMode, GoalProps as GoalFormProps } from 'components/forms/goal/type';
import { Logo, CloseIcon, BoxArrowUpRight } from 'components/core/icons/Icons';
import GoalConfirmation from 'components/goals/confirmation/Confirmation';
import Button from 'components/core/button/Button';
import bn from 'helper/bemnames';
import { getGoalHubPage } from 'helper/common';
import { dispatchSegmentEvent, SegmentEvent } from 'helper/segment';
import { AppDispatch, useAppDispatch } from 'reducers/store';
const bem = bn.create('goal-modal');
export type GoalModalProps = BaseModalProps & {
  isShowConfirmation?: boolean;
  setIsShowConfirmation?: (status: boolean) => void;
};
export interface Props {
  modalProps: GoalModalProps;
  formProps: GoalFormProps;
}

const GoalModal: React.FC<Props> = (props: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [bottomHit, setBottomHit] = useState(false);
  const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight;
    setBottomHit(bottom);
  };
  const {
    modalProps: { isShowConfirmation, setIsShowConfirmation, ...restModalProps },
    formProps,
  } = props;
  return (
    <BaseModal
      {...restModalProps}
      closeOnOutsideClick={false}
      className={bem.b([restModalProps.className])}
      header={
        <div className={bem.e('header')}>
          <div className={bem.e('logo')}>
            <Logo width={42} height={42} />
          </div>
          <div className={bem.e('header-title')}>
            <h2 className={bem.e('title')}>Cascade</h2>
            <p className={bem.e('subtitle')} data-testid='modal-goal-title'>
              {formProps.action === ActionMode.Create ? 'Create Goal' : 'Goal'}
            </p>
          </div>
          <div
            className={bem.e('close')}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              restModalProps.onCancel && restModalProps?.onCancel(event);
              setIsShowConfirmation && setIsShowConfirmation(false);
            }}
          >
            <CloseIcon size='large' />
          </div>
        </div>
      }
      content={
        <div className={bem.e('modal-content')} onScroll={e => scrollHandler(e)}>
          {isShowConfirmation ? (
            <GoalConfirmation goal={formProps.goal} instanceName={formProps.instanceName} />
          ) : (
            <>
              <GoalForm {...formProps} />
              {formProps.action === ActionMode.Create ? null : (
                <Feed bottomHit={bottomHit} instanceName={formProps.instanceName} goal={formProps.goal} />
              )}
            </>
          )}
        </div>
      }
      footer={
        formProps.action == 'edit' ? (
          <div className={bem.e('link')}>
            <Button
              size='small'
              onClick={() => {
                if (!formProps.goal) return;
                window.open(getGoalHubPage(formProps.instanceName, formProps.goal?.id));
                dispatchSegmentEvent(SegmentEvent.OpenInCascade, dispatch);
              }}
              className={bem.e('btn-link')}
              icon={<BoxArrowUpRight />}
              content='Open in Cascade'
              text
            />
          </div>
        ) : null
      }
    ></BaseModal>
  );
};
export default GoalModal;

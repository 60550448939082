import React, { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { getAccessToken } from 'api/auth';
import { useQuery } from 'hook/useQuery';
import { fetchInstanceRegion } from 'api/instance';

const AuthorizeWindow: React.FC = () => {
  const query: URLSearchParams = useQuery();
  const error = query.get('error');
  useEffect(() => {
    microsoftTeams.app.initialize();
    const code = query.get('code');
    const instance = query.get('state');
    if (code && instance) {
      getAccessToken({
        code: code,
        instance: instance,
      })
        .then(({ data }) => {
          fetchInstanceRegion(instance).then(({ instanceRegion, leadId }) => {
            microsoftTeams.authentication.notifySuccess(JSON.stringify({ ...data, instance, instanceRegion, leadId }));
          });
        })
        .catch(exception => {
          microsoftTeams.authentication.notifyFailure(JSON.stringify(exception));
        });
    }
    if (error) {
      microsoftTeams.authentication.notifyFailure('access_denied');
      window.close();
    }
  }, []);
  return <h3>DONE...</h3>;
};
export default AuthorizeWindow;

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import bn from 'helper/bemnames';
import { Form, FormField, Flex, Divider } from '@fluentui/react-northstar';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { get, omit, includes, filter, lowerCase, trim, toString } from 'lodash';
import classNames from 'classnames';
// components
import FormLabel from 'components/core/form-label/FormLabel';
import Button from 'components/core/button/Button';
import FormDatepicker from 'components/core/form-datepicker/FormDatepicker';
import FormInput from 'components/core/form-input/FormInput';
import FormTextArea from 'components/core/form-text-area/FormTextArea';
import FormDropdown from 'components/core/form-dropdown/FormDropdown';
import { Item as DropdownItem } from 'components/core/dropdown/type';
import { FormGoalProps } from 'api/type';

// reducers
import { Goal, GoalProgressStatus } from 'reducers/goal-slice/type';
import { Template } from 'reducers/template-slice/type';
import { Weight } from 'reducers/weight-slice/type';
import { User } from 'reducers/user-slice/type';
import { Plan, PlanIncluded } from 'reducers/plan-slice/type';
import {
  ActionMode,
  GoalCustomAttributes,
  GoalFormProps,
  GoalProps,
  GoalSettings,
  validationSchemaCreate,
  validationSchemaEdit,
} from './type';
// other
import { roundedNumber, formatFormGoalProps, formatNumber } from 'helper/common';

import FormStatusDropDown from 'components/core/form-dropdown/FormStatusDropDown';
import { getManualStatusByKey } from 'helper/goal';
import Status from 'components/goals/status/Status';

const bem = bn.create('goal-form');

const GoalForm: React.FunctionComponent<GoalProps> = (props: GoalProps) => {
  const [disableSelectParentGoal, setDisableSelectParentGoal] = useState(false);
  const {
    onSubmit,
    goal,
    action,
    config,
    templatesOptions,
    weightsOptions,
    usersOptions,
    plansOptions,
    goalsByPlanOptions,
    fetchGoalsByPlan,
  } = props;
  const isCreate = action === ActionMode.Create;
  const isEdit = action === ActionMode.Edit;
  const disabled = action === ActionMode.View;
  const [formKey, setFormKey] = useState<string>(uuidv4());
  const isReadOnlyProgress = goal && goal.attributes.settings && goal.attributes.settings?.tracking.tracking_type !== 'MANUAL';
  const allowManualStatus = config.filter(configItem => {
    if (configItem.id == 'system-goalManualStatus') {
      return true;
    }
  })[0];

  const formik = useFormik({
    initialValues: props.initialValues,
    enableReinitialize: !isCreate,
    validationSchema: isCreate ? validationSchemaCreate : validationSchemaEdit,
    onSubmit: (values: GoalFormProps, formikHelpers) => {
      const newValues: FormGoalProps = formatFormGoalProps(values);

      onSubmit(newValues, () => {
        formikHelpers.setSubmitting(false);
      });
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const currentManual = formik.values.settings as GoalSettings;
  const initialManual = formik.initialValues.settings as GoalSettings;
  const dirtyManual =
    (currentManual && currentManual.tracking.manualStatus ? currentManual.tracking.manualStatus : false) !=
    (initialManual && initialManual.tracking.manualStatus ? initialManual.tracking.manualStatus : false);
  const currentReservedStatus = formik.values.custom_attributes as GoalCustomAttributes;
  const initialReserverdStaus = formik.initialValues.custom_attributes as GoalCustomAttributes;

  //reserved status is dirty if changed the reserved_status and manualStatus = true

  const dirtyReserverdStatus =
    (currentReservedStatus && currentReservedStatus.RESERVED_SYSTEM_STATUS && currentReservedStatus.RESERVED_SYSTEM_STATUS[0]
      ? currentReservedStatus.RESERVED_SYSTEM_STATUS[0]
      : false) !=
      (initialReserverdStaus && initialReserverdStaus.RESERVED_SYSTEM_STATUS && initialReserverdStaus.RESERVED_SYSTEM_STATUS[0]
        ? initialReserverdStaus.RESERVED_SYSTEM_STATUS[0]
        : false) &&
    currentManual &&
    currentManual.tracking.manualStatus == true;
  const { errors, touched, values } = formik;

  const minDate = new Date(formik.values.start_time.getTime() + 86400000); // + 1 day in ms
  const maxDate = new Date(formik.values.end_time.getTime() - 86400000); // - 1 day in ms
  const focusAreaOptions: DropdownItem<PlanIncluded>[] =
    values.plan && values.plan.data
      ? values.plan.data.relationships.focusAreas.data.map(item => ({
          header: item.attributes ? item.attributes?.name : '',
          data: item,
        }))
      : [];
  const goalOrFocusAreaOptions = goalsByPlanOptions ? [...focusAreaOptions, ...goalsByPlanOptions] : focusAreaOptions;
  const onFetchGoalsByPlan = (plan: DropdownItem<Plan>) => {
    if (!plan.data) return;
    setDisableSelectParentGoal(true);
    fetchGoalsByPlan(
      {
        id: plan.data.id,
      },
      goalsByPlan => {
        const focusAreaOptions: DropdownItem<PlanIncluded>[] = plan.data
          ? plan.data.relationships.focusAreas.data.map(item => ({
              header: item.attributes ? item.attributes?.name : '',
              data: item,
            }))
          : [];
        const goalsByPlanOptions = goalsByPlan.map((goal: Goal) => ({
          header: goal.attributes.title,
          data: goal,
        }));
        const goalOrFocusAreaOptions = goalsByPlanOptions ? [...focusAreaOptions, ...goalsByPlanOptions] : focusAreaOptions;
        formik.setFieldValue('aligned_to', goalOrFocusAreaOptions[0]);
        setDisableSelectParentGoal(false);
      },
    );
  };

  return (
    <div className={bem.b()}>
      <Form onSubmit={() => formik.handleSubmit()}>
        <FormField>
          <FormLabel content='Goal Title' />
          <FormInput
            fluid
            name='title'
            disabled={disabled}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.title}
            error={!!(errors.title && touched.title)}
            errorMessage={errors.title}
            isShowActions={isEdit && !formik.isSubmitting && formik.initialValues.title !== formik.values.title}
            dirty={isEdit && !formik.isSubmitting && formik.initialValues.title !== formik.values.title}
            actionsProps={{
              handleSave: () => formik.handleSubmit(),
              handleBack: () => formik.setFieldValue('title', formik.initialValues.title),
            }}
          />
        </FormField>
        {!isCreate && (
          <React.Fragment>
            <Flex gap='gap.large' className={bem.e('flex-responsive')}>
              <Flex.Item size='size.large'>
                <FormField>
                  <FormLabel content='Current Progress' />
                  <div className={bem.e('progress')}>
                    <FormInput
                      fluid
                      name='progress'
                      type='text'
                      min='0'
                      pattern='[+-]?\d+(?:[.,]\d+)?'
                      readOnly={isReadOnlyProgress}
                      disabled={disabled}
                      onBlur={formik.handleBlur}
                      onFocus={() => formik.setFieldTouched('progress', true)}
                      onChange={formik.handleChange}
                      value={trim(toString(formik.values.progress))}
                      error={!!(errors.progress && touched.progress)}
                      errorMessage={errors.progress}
                      isShowActions={
                        isEdit && !formik.isSubmitting && Number(formik.initialValues.progress) !== Number(formik.values.progress)
                      }
                      dirty={isEdit && !formik.isSubmitting && Number(formik.initialValues.progress) !== Number(formik.values.progress)}
                      actionsProps={{
                        handleSave: () => formik.handleSubmit(),
                        handleBack: () => formik.setFieldValue('progress', formik.initialValues.progress),
                      }}
                    />
                    <span
                      className={classNames(bem.e('million'), {
                        [bem.e('million-read-only')]: isReadOnlyProgress,
                      })}
                    >
                      {' '}
                      /{goal?.attributes.target ? formatNumber(goal?.attributes.target) : 100}
                    </span>
                  </div>
                </FormField>
              </Flex.Item>
              <Flex.Item size='size.large'>
                <FormField>
                  <FormLabel content='Status' />
                  <div className={bem.e('status')}>
                    {((allowManualStatus == undefined && !isReadOnlyProgress) ||
                      (!isReadOnlyProgress && allowManualStatus && allowManualStatus.attributes.value == '1')) && (
                      <FormStatusDropDown
                        manual={JSON.parse(JSON.stringify(get(formik, 'values.settings')))['tracking']['manualStatus']}
                        handleDropDownChange={(data: string) => {
                          const currentSettings = JSON.parse(JSON.stringify(get(formik, 'values.settings')));
                          let currentCustomAttributes = JSON.parse(JSON.stringify(get(formik, 'values.custom_attributes')));

                          if (currentSettings == []) {
                            currentSettings['tracking'] = {};
                          }
                          if (!currentSettings['tracking']) {
                            currentSettings['tracking'] = {};
                          }

                          currentSettings['tracking']['manualStatus'] = data == 'auto' ? false : true;
                          if (data != 'auto') {
                            if (currentCustomAttributes == '') {
                              currentCustomAttributes = {};
                            }
                            currentCustomAttributes.RESERVED_SYSTEM_STATUS = [data];

                            formik.setFieldValue('statusText', getManualStatusByKey(data));
                          } else {
                            formik.setFieldValue('statusText', goal?.attributes.progress_status);
                          }

                          currentCustomAttributes = JSON.parse(JSON.stringify(currentCustomAttributes));

                          formik.setFieldValue('settings', currentSettings);
                          formik.setFieldValue('custom_attributes', currentCustomAttributes);
                        }}
                        disabled={disabled}
                        value={values.statusText}
                        error={!!(errors.settings && touched.settings)}
                        errorMessage={errors.entityTemplate}
                        isShowActions={isEdit && !formik.isSubmitting && (dirtyManual || dirtyReserverdStatus)}
                        dirty={isEdit && !formik.isSubmitting && (dirtyManual || dirtyReserverdStatus)}
                        actionsProps={{
                          handleSave: () => formik.handleSubmit(),
                          handleBack: () => {
                            formik.setFieldValue('settings', formik.initialValues.settings);
                            formik.setFieldValue('custom_attributes', formik.initialValues.custom_attributes);
                            formik.setFieldValue('statusText', formik.initialValues.statusText);
                            formik.setErrors(omit(errors, ['settings']));
                            setFormKey(uuidv4());
                          },
                        }}
                      />
                    )}
                    {((allowManualStatus && allowManualStatus.attributes.value == '' && isReadOnlyProgress) ||
                      (allowManualStatus == undefined && isReadOnlyProgress)) && (
                      <Status status={formik.values.progress_status as GoalProgressStatus} />
                    )}
                    <span className={bem.e('percent')}>{roundedNumber(2, Number(formik.values.progress_percentage))}%</span>
                  </div>
                </FormField>
              </Flex.Item>
            </Flex>
            <Divider />
          </React.Fragment>
        )}
        <Flex gap='gap.large' className={bem.e('flex-responsive')}>
          <Flex.Item size='size.half'>
            <FormField>
              <FormLabel content='Goal Type' />
              <FormDropdown
                items={templatesOptions}
                placeholder=''
                onChange={(e, data) => {
                  if (data.value && typeof data.value === 'object') {
                    const value = data.value as DropdownItem<Template>;
                    formik.setFieldValue('entityTemplate', value);
                  } else {
                    formik.setFieldValue('entityTemplate', undefined);
                  }
                }}
                key={`template-${formKey}-${get(values, 'entityTemplate.data.id', '')}`}
                disabled={disabled}
                value={values.entityTemplate}
                error={!!(errors.entityTemplate && touched.entityTemplate)}
                errorMessage={errors.entityTemplate}
                isShowActions={
                  isEdit &&
                  !formik.isSubmitting &&
                  get(formik, 'initialValues.entityTemplate.data.id') !== get(formik, 'values.entityTemplate.data.id')
                }
                dirty={
                  isEdit &&
                  !formik.isSubmitting &&
                  get(formik, 'initialValues.entityTemplate.data.id') !== get(formik, 'values.entityTemplate.data.id')
                }
                actionsProps={{
                  handleSave: () => formik.handleSubmit(),
                  handleBack: () => {
                    formik.setFieldValue('entityTemplate', formik.initialValues.entityTemplate);
                    formik.setErrors(omit(errors, ['entityTemplate']));
                    setFormKey(uuidv4());
                  },
                }}
              />
            </FormField>
          </Flex.Item>
          <Flex.Item size='size.half'>
            <Flex gap='gap.large'>
              <Flex.Item size='size.half'>
                <FormField>
                  <FormLabel content='Start Date' />
                  <FormDatepicker
                    disabled={disabled}
                    maxDate={maxDate}
                    onChange={(value: Date) => {
                      formik.setFieldValue('start_time', value);
                    }}
                    value={formik.values.start_time}
                    error={!!(errors.start_time && touched.start_time)}
                    errorMessage={errors.start_time as string}
                    isShowActions={
                      isEdit &&
                      !formik.isSubmitting &&
                      moment(formik.values.start_time).diff(moment(formik.initialValues.start_time), 'days') !== 0
                    }
                    dirty={
                      isEdit &&
                      !formik.isSubmitting &&
                      moment(formik.values.start_time).diff(moment(formik.initialValues.start_time), 'days') !== 0
                    }
                    actionsProps={{
                      handleSave: () => formik.handleSubmit(),
                      handleBack: () => formik.setFieldValue('start_time', formik.initialValues.start_time),
                    }}
                  />
                </FormField>
              </Flex.Item>
              <Flex.Item size='size.half'>
                <FormField>
                  <FormLabel content='Due Date' />
                  <FormDatepicker
                    disabled={disabled}
                    minDate={minDate}
                    onChange={(value: Date) => {
                      formik.setFieldValue('end_time', value);
                    }}
                    value={formik.values.end_time}
                    error={!!(errors.end_time && touched.end_time)}
                    errorMessage={errors.end_time as string}
                    isShowActions={
                      isEdit &&
                      !formik.isSubmitting &&
                      moment(formik.values.end_time).diff(moment(formik.initialValues.end_time), 'days') !== 0
                    }
                    dirty={
                      isEdit &&
                      !formik.isSubmitting &&
                      moment(formik.values.end_time).diff(moment(formik.initialValues.end_time), 'days') !== 0
                    }
                    actionsProps={{
                      handleSave: () => formik.handleSubmit(),
                      handleBack: () => {
                        formik.setFieldValue('end_time', formik.initialValues.end_time);
                      },
                    }}
                  />
                </FormField>
              </Flex.Item>
            </Flex>
          </Flex.Item>
        </Flex>
        <Flex gap='gap.large' className={bem.e('flex-responsive')}>
          <Flex.Item size='size.half'>
            <FormField>
              <FormLabel content='Owner' />
              <FormDropdown
                items={usersOptions}
                placeholder=''
                search={(items, searchQuery) => {
                  const isShowAll = values.owner?.header === searchQuery;
                  if (isShowAll || !searchQuery) return items;
                  const results = filter(items, (item: DropdownItem<User>) => {
                    return includes(lowerCase(item.header), lowerCase(searchQuery));
                  });
                  return results;
                }}
                defaultSearchQuery={values.owner?.header}
                disabled={disabled}
                onChange={(e, data) => {
                  if (data.value) {
                    const value = data.value as DropdownItem<User>;
                    formik.setFieldValue('owner', value);
                  } else {
                    formik.setFieldValue('owner', '');
                  }
                }}
                key={`owner-${formKey}-${get(values, 'owner.data.id', '')}`}
                value={values.owner}
                error={!!(errors.owner && touched.owner)}
                errorMessage={errors.owner}
                isShowActions={
                  isEdit && !formik.isSubmitting && get(formik, 'initialValues.owner.data.id') !== get(formik, 'values.owner.data.id')
                }
                dirty={isEdit && !formik.isSubmitting && get(formik, 'initialValues.owner.data.id') !== get(formik, 'values.owner.data.id')}
                actionsProps={{
                  handleSave: () => formik.handleSubmit(),
                  handleBack: () => {
                    formik.setFieldValue('owner', formik.initialValues.owner);
                    formik.setErrors(omit(errors, ['owner']));
                    setFormKey(uuidv4()); // generate new key for re-index dropdown
                  },
                }}
              />
            </FormField>
          </Flex.Item>
          <Flex.Item size='size.half'>
            <FormField>
              <FormLabel content='Weight' />
              <FormDropdown
                items={weightsOptions}
                placeholder=''
                disabled={disabled}
                key={`weight-${formKey}-${values.weight ? values.weight.header : ''}`}
                onChange={(e, data) => {
                  if (data.value && typeof data.value === 'object') {
                    const value = data.value as DropdownItem<Weight>;
                    formik.setFieldValue('weight', value);
                  } else {
                    formik.setFieldValue('weight', undefined);
                  }
                }}
                value={values.weight}
                error={!!(errors.weight && touched.weight)}
                errorMessage={errors.weight}
                isShowActions={
                  isEdit && !formik.isSubmitting && get(formik, 'initialValues.weight.data.id') !== get(formik, 'values.weight.data.id')
                }
                dirty={
                  isEdit && !formik.isSubmitting && get(formik, 'initialValues.weight.data.id') !== get(formik, 'values.weight.data.id')
                }
                actionsProps={{
                  handleSave: () => formik.handleSubmit(),
                  handleBack: () => {
                    formik.setFieldValue('weight', formik.initialValues.weight);
                    setFormKey(uuidv4()); // generate new key for re-index dropdown
                  },
                }}
              />
            </FormField>
          </Flex.Item>
        </Flex>
        <FormField>
          <FormLabel content='Description' />
          <FormTextArea
            onChange={event => {
              const target = event.target as HTMLTextAreaElement;
              formik.setFieldValue('description', target.value);
            }}
            disabled={disabled}
            value={formik.values.description}
            error={!!(errors.description && touched.description)}
            errorMessage={errors.description}
            isShowActions={isEdit && !formik.isSubmitting && formik.initialValues.description !== formik.values.description}
            dirty={isEdit && !formik.isSubmitting && formik.initialValues.description !== formik.values.description}
            actionsProps={{
              handleSave: () => formik.handleSubmit(),
              handleBack: () => formik.setFieldValue('description', formik.initialValues.description),
            }}
          />
        </FormField>
        {isCreate ? (
          <React.Fragment>
            <Divider />
            <Flex gap='gap.large' className={bem.e('flex-responsive')}>
              <Flex.Item size='size.half'>
                <FormField>
                  <FormLabel content='Select Plan' />
                  <FormDropdown
                    items={plansOptions}
                    placeholder=''
                    disabled={disabled}
                    onChange={(e, data) => {
                      if (data.value && typeof data.value === 'object') {
                        const value = data.value as DropdownItem<Plan>;
                        formik.setFieldValue('plan', value);
                        onFetchGoalsByPlan(value);
                      } else {
                        formik.setFieldValue('plan', undefined);
                      }
                      formik.setFieldValue('aligned_to', undefined);
                      setFormKey(uuidv4()); // generate new key for re-index dropdown
                    }}
                    value={values.plan}
                    error={!!(errors.plan && touched.plan)}
                    errorMessage={errors.plan}
                    isShowActions={isEdit && !formik.isSubmitting && formik.initialValues.plan !== formik.values.plan}
                    dirty={isEdit && !formik.isSubmitting && formik.initialValues.plan !== formik.values.plan}
                    actionsProps={{
                      handleSave: () => formik.handleSubmit(),
                      handleBack: () => formik.setFieldValue('plan', formik.initialValues.plan),
                    }}
                  />
                </FormField>
              </Flex.Item>
              <Flex.Item size='size.half'>
                <FormField className={bem.e('focus-area-select')}>
                  <FormLabel content='Select parent Goal or Focus area' />
                  <FormDropdown
                    items={goalOrFocusAreaOptions}
                    placeholder=''
                    disabled={disabled || !values.plan || disableSelectParentGoal}
                    loading={disableSelectParentGoal}
                    onChange={(e, data) => {
                      if (data.value && typeof data.value === 'object') {
                        const value = data.value as DropdownItem<PlanIncluded | Goal>;
                        formik.setFieldValue('aligned_to', value);
                      } else {
                        formik.setFieldValue('aligned_to', undefined);
                      }
                    }}
                    key={`aligned_to-${formKey}`}
                    value={values.aligned_to}
                    error={!!(errors.aligned_to && touched.aligned_to)}
                    errorMessage={errors.aligned_to}
                    isShowActions={isEdit && !formik.isSubmitting && formik.initialValues.aligned_to !== formik.values.aligned_to}
                    dirty={isEdit && !formik.isSubmitting && formik.initialValues.aligned_to !== formik.values.aligned_to}
                    actionsProps={{
                      handleSave: () => formik.handleSubmit(),
                      handleBack: () => formik.setFieldValue('aligned_to', formik.initialValues.aligned_to),
                    }}
                  />
                </FormField>
              </Flex.Item>
            </Flex>
            <div className={bem.e('submit')}>
              <Button size='medium' content='Save' disabled={formik.isSubmitting} primary loading={formik.isSubmitting} type='submit' />
            </div>
          </React.Fragment>
        ) : null}
      </Form>
    </div>
  );
};
export default GoalForm;

import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'reducers/store';
import { selectIsAuthenticated, selectAuth } from 'reducers/auth-slice/selector';
import { apiClient } from 'api/client';
import { prepareAuthorizationToken, prepareAPIBaseUrl } from 'helper/string';
import { useParamsUpdateTokenState } from 'hook/useParamsUpdateTokenState';
import { useQuery } from 'hook/useQuery';
import { Base64 } from 'js-base64';
interface Props extends RouteProps {
  // eslint-disable-next-line
  component: any;
}
const PrivateBotRoute: React.FunctionComponent<Props> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  useParamsUpdateTokenState();
  const query: URLSearchParams = useQuery();
  const descKey = query.get('description');
  const desc = Base64.decode(descKey ?? '');
  const { tokenState, instanceName, instanceRegion } = useAppSelector(selectAuth);
  if (isAuthenticated && tokenState) {
    apiClient.defaults.headers.common['Authorization'] = prepareAuthorizationToken(tokenState.accessToken, tokenState.tokenType);
    apiClient.defaults.baseURL = prepareAPIBaseUrl(instanceName, instanceRegion);
  }

  return (
    // Show the component only when the user is logged in
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Component {...props} desc={desc} isFromBot={true} /> : <div data-testid='private-route-empty' />
      }
    />
  );
};

export default PrivateBotRoute;

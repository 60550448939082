import { canAccessTemplate } from 'helper/feedinputs';
import React from 'react';
import { Goal } from 'reducers/goal-slice/type';
import { CustomTemplate, Template } from 'reducers/template-slice/type';
import { useAppSelector } from 'reducers/store';
import { Included, User, UserIncluded } from 'reducers/user-slice/type';
import { selectUser, selectUserIncluded } from 'reducers/auth-slice/selector';

interface Props {
  selectedUpdate: string | CustomTemplate;
  updateEntityTemplates: Template[] | undefined;
  goal: Goal | undefined;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FeedButton: React.FC<Props> = ({ selectedUpdate, updateEntityTemplates, goal, handleChange }) => {
  const user = useAppSelector<User | undefined>(selectUser);
  const userIncluded = useAppSelector<Included | undefined>(selectUserIncluded);

  return selectedUpdate === 'none' ? (
    <div className='addButton'>
      <span className='material-icons'>add</span>
      {selectedUpdate === 'none' ? (
        <select value={selectedUpdate} onChange={e => handleChange(e)}>
          <option value='none' disabled>
            Add Update
          </option>
          {updateEntityTemplates?.map(opt => {
            if (goal?.relationships.entityTemplate.data?.id) {
              const id = +goal?.relationships.entityTemplate.data?.id;
              if (
                !opt.attributes.settings?.restriction.templateIDBlacklist ||
                (opt.attributes.settings?.restriction.templateIDBlacklist &&
                  opt.attributes.is_archived === 0 &&
                  canAccessTemplate(user as User, opt.attributes.settings, (userIncluded as unknown) as UserIncluded[]) &&
                  !opt.attributes.settings?.restriction.templateIDBlacklist.includes(id))
              ) {
                return (
                  <option value={opt.id} key={opt.id}>
                    {opt.attributes.name}
                  </option>
                );
              } else {
                return null;
              }
            }
          })}
        </select>
      ) : null}
    </div>
  ) : null;
};

export default FeedButton;

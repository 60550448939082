import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { Attributes, FetchUpdatesResponse } from '../type';
import { createUpdateApi } from 'api/update';
import { StatusCode } from 'type/error';
import { UpdateState } from '../type';

export const createUpdatesThunk = createAsyncThunk<
  FetchUpdatesResponse,
  Attributes,
  {
    rejectValue: ValidationErrors;
  }
>('update/createUpdate', async option => {
  try {
    const response = await createUpdateApi(option);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const createUpdatesReducer = (builder: ActionReducerMapBuilder<UpdateState>): void => {
  builder.addCase(createUpdatesThunk.fulfilled, state => {
    state.error = undefined;
    state.isLoading = false;
  });
  builder.addCase(createUpdatesThunk.pending, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(createUpdatesThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

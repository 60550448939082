import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { FetchUpdatesResponse, update } from '../type';
import { deleteUpdateApi } from 'api/update';
import { StatusCode } from 'type/error';
import { UpdateState } from '../type';

export const deleteUpdatesThunk = createAsyncThunk<
  FetchUpdatesResponse,
  update,
  {
    rejectValue: ValidationErrors;
  }
>('update/deleteUpdate', async option => {
  try {
    const response = await deleteUpdateApi(option);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const deleteUpdatesReducer = (builder: ActionReducerMapBuilder<UpdateState>): void => {
  builder.addCase(deleteUpdatesThunk.fulfilled, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(deleteUpdatesThunk.pending, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(deleteUpdatesThunk.rejected, (state, action) => {
    state.isLoading = true;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import { apiClient } from './client';
import { FetchUserProps, FetchUserResponse, FetchUsersProps, FetchUsersResponse } from './type';

export const fetchUsersApi = async ({ page, keyword, sort, size, filter, include }: FetchUsersProps): Promise<FetchUsersResponse> => {
  const url = '/users';

  const { data } = await apiClient.get(url, { params: { 'page[page]': page, keyword, sort, 'page[size]': size, filter, include } });
  return data;
};

export const fetchUserApi = async ({ id }: FetchUserProps): Promise<FetchUserResponse> => {
  const url = `/weights/${id}`;

  const { data } = await apiClient.get<FetchUserResponse>(url, {
    params: { include: 'relationships' },
  });
  return data;
};

export default { fetchUsersApi, fetchUserApi };

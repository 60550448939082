import React from 'react';
import bn from 'helper/bemnames';
import { Goal } from 'reducers/goal-slice/type';
import { SuccessIcon, BoxArrowUpRight } from 'components/core/icons/Icons';
import Button from 'components/core/button/Button';
import { getGoalHubPage } from 'helper/common';
import { useQuery } from 'hook/useQuery';
import { Base64 } from 'js-base64';

import { AppDispatch, useAppDispatch } from 'reducers/store';
import { dispatchSegmentEvent, SegmentEvent } from 'helper/segment';
const bem = bn.create('goal-confirmation');
interface Props {
  goal?: Goal;
  instanceName: string;
}
const BotGoalConfirmation: React.FC<Props> = () => {
  const query: URLSearchParams = useQuery();
  const tokenKey = query.get('key');
  const dispatch: AppDispatch = useAppDispatch();

  const keyObj: {
    instanceName: string;
    goalId: string;
    goalTittle: string;
  } = JSON.parse(Base64.decode(tokenKey ?? ''));

  return (
    <div className={bem.b()}>
      <div className={bem.e('success-image')}>
        <SuccessIcon width={80} height={80} />
      </div>
      <span className={bem.e('text')}>Goal &apos;{keyObj?.goalTittle}&apos; has been successfully created.</span>
      <div className={bem.e('link')}>
        <Button
          size='small'
          disabled={!keyObj}
          onClick={() => {
            if (keyObj) {
              window.open(getGoalHubPage(keyObj.instanceName, keyObj.goalId));
              dispatchSegmentEvent(SegmentEvent.OpenInCascade, dispatch);
            }
          }}
          data-testid='confirmation-open-goal'
          className={bem.e('btn-link')}
          icon={<BoxArrowUpRight />}
          content='Open in Cascade'
          text
        />
      </div>
    </div>
  );
};
export default BotGoalConfirmation;

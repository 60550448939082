import { createSlice } from '@reduxjs/toolkit';
import { fetchTemplatesReducer } from './reducers/fetchTemplatesReducer';

import { TemplateState } from './type';
const initialState: TemplateState = { templates: [], isLoading: false, attributesIncluded: [] };

const { reducer, actions } = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchTemplatesReducer(builder);
  },
});
export { reducer, actions as templateAction };

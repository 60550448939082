/* eslint-disable max-lines-per-function */
import React, { useState, useRef } from 'react';
import bn from 'helper/bemnames';
import classNames from 'classnames';
import { DropdownProps } from '@fluentui/react-northstar';
import Status from 'components/goals/status/Status';
import { GoalProgressStatus } from 'reducers/goal-slice/type';
import { manualStatusTranformMap } from 'helper/goal';
import { ReloadIcon } from '../icons/Icons';
import { AcceptIcon } from '@fluentui/react-icons-northstar';
import useOnClickOutside from 'hook/useOnClickOutside';

const bem = bn.create('statusDropdown');
type Props = DropdownProps & {
  handleDropDownChange: (e: GoalProgressStatus | string) => void;

  manual: boolean | undefined;
  pointer?: boolean;
};

const StatusDropDown: React.FC<Props> = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { handleDropDownChange, value, manual } = props;
  const ref = useRef(null);

  const toogleVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsVisible(prev => {
      return !prev;
    });
  };
  useOnClickOutside(ref, () => {
    setIsVisible(false);
  });
  return (
    <div ref={ref}>
      <Status onClick={toogleVisibility} status={value as GoalProgressStatus} pointer={props.pointer} />

      {isVisible && (
        <div className={bem.e('dropdown-content')}>
          <div
            className={bem.e('auto-tracking-item')}
            onClick={() => {
              setIsVisible(false);
              handleDropDownChange('auto');
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('auto-tracking'))}>
              <i className={bem.e('before-icon')}>
                <ReloadIcon color='#217AFF' />
              </i>
              Auto tracking
            </span>
            {(manual == undefined || manual == false) && (
              <i className={bem.e('after-icon-auto')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
          <div className={bem.e('separator')}></div>
          <div className={bem.e('group-label')}>Manual</div>
          <div
            className={bem.e('dropdown-item')}
            onClick={() => {
              setIsVisible(false);

              const manualStatusKey = manualStatusTranformMap.filter(status => status.value == GoalProgressStatus.NOT_STARTED)[0].key;
              handleDropDownChange(manualStatusKey);
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('not-start'))}>Not Started</span>
            {manual == true && value == GoalProgressStatus.NOT_STARTED && (
              <i className={bem.e('after-icon')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
          <div
            className={bem.e('dropdown-item')}
            onClick={() => {
              setIsVisible(false);

              const manualStatusKey = manualStatusTranformMap.filter(status => status.value == GoalProgressStatus.BEHIND)[0].key;
              handleDropDownChange(manualStatusKey);
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('behind'))}>Behind</span>

            {manual == true && value == GoalProgressStatus.BEHIND && (
              <i className={bem.e('after-icon')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
          <div
            className={bem.e('dropdown-item')}
            onClick={() => {
              setIsVisible(false);

              const manualStatusKey = manualStatusTranformMap.filter(status => status.value == GoalProgressStatus.ON_TRACK)[0].key;
              handleDropDownChange(manualStatusKey);
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('on-track'))}>On Track</span>
            {manual == true && value == GoalProgressStatus.ON_TRACK && (
              <i className={bem.e('after-icon')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
          <div
            className={bem.e('dropdown-item')}
            onClick={() => {
              setIsVisible(false);

              const manualStatusKey = manualStatusTranformMap.filter(status => status.value == GoalProgressStatus.OVERDUE)[0].key;
              handleDropDownChange(manualStatusKey);
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('overdue'))}>Overdue</span>
            {manual == true && value == GoalProgressStatus.OVERDUE && (
              <i className={bem.e('after-icon')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
          <div
            className={bem.e('dropdown-item')}
            onClick={() => {
              setIsVisible(false);

              const manualStatusKey = manualStatusTranformMap.filter(status => status.value == GoalProgressStatus.COMPLETE)[0].key;
              handleDropDownChange(manualStatusKey);
            }}
          >
            <span className={classNames(bem.e('button-status'), bem.e('complete'))}>Complete</span>
            {manual == true && value == GoalProgressStatus.COMPLETE && (
              <i className={bem.e('after-icon')}>
                <AcceptIcon size='small' />
              </i>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusDropDown;

// import jwtDecode, { JwtPayload } from 'jwt-decode';
// import moment from 'moment';
import { isEmpty, isNil } from 'ramda';

export const isValidToken = (accessToken: string | undefined): boolean => {
  if (isNil(accessToken) || isEmpty(accessToken)) {
    return false;
  }
  return true;

  // try {
  //   const decoded = jwtDecode<JwtPayload>(accessToken);
  //   if (decoded.exp) {
  //     return moment() < moment(decoded.exp * 1000);
  //   }
  //   return false;
  // } catch (error) {
  //   return false;
  // }
};

import { RootState } from 'reducers/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { StatusCode } from 'type/error';

export const selectIsSystemError = createSelector(
  (state: RootState) => state.goal.error,
  (state: RootState) => state.plan.error,
  (state: RootState) => state.template.error,
  (state: RootState) => state.weight.error,
  (state: RootState) => state.user.error,
  (goalError, planError, templateError, weightError, userError) => {
    const goalErrorStatusCode = goalError ? Number(goalError) === StatusCode.NotFound || Number(goalError) === StatusCode.Offline : false;
    const planErrorStatusCode = planError ? Number(planError) === StatusCode.NotFound || Number(planError) === StatusCode.Offline : false;
    const templateErrorStatusCode = templateError
      ? Number(templateError) === StatusCode.NotFound || Number(templateError) === StatusCode.Offline
      : false;
    const weightErrorStatusCode = weightError
      ? Number(weightError) === StatusCode.NotFound || Number(weightError) === StatusCode.Offline
      : false;
    const userErrorStatusCode = userError ? Number(userError) === StatusCode.NotFound || Number(userError) === StatusCode.Offline : false;

    return goalErrorStatusCode || planErrorStatusCode || templateErrorStatusCode || weightErrorStatusCode || userErrorStatusCode;
  },
);

/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { FetchGoalsByPlanProps, FetchGoalsResponse } from 'api/type';
import { fetchGoalsByPlanApi } from 'api/plan';
import { PlanState } from 'reducers/plan-slice/type';
import { StatusCode } from 'type/error';

export const fetchGoalsByPlanThunk = createAsyncThunk<
  FetchGoalsResponse,
  FetchGoalsByPlanProps,
  {
    rejectValue: ValidationErrors;
  }
>('plan/fetchGoalsByPlanPlanner', async option => {
  try {
    const response = await fetchGoalsByPlanApi(option);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchGoalsByPlanReducer = (builder: ActionReducerMapBuilder<PlanState>): void => {
  builder.addCase(fetchGoalsByPlanThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.goalsByPlan = payload.data;
  });
  builder.addCase(fetchGoalsByPlanThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchGoalsByPlanThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

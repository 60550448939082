import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reducers/rootReducer';

import { isValidToken } from 'helper/token';
import { Included, User } from 'reducers/user-slice/type';
import { AuthState } from './type';
import { isEmpty } from 'ramda';

export const selectIsAuthenticated = createSelector(
  (state: RootState) => state.auth.tokenState,
  tokenState => !isEmpty(tokenState?.instanceRegion) && isValidToken(tokenState?.accessToken),
);

export const selectError = createSelector(
  (state: RootState) => state.auth.error,
  error => error || undefined,
);
export const selectAuth = (state: RootState): AuthState => state.auth;

export const selectUser = (state: RootState): User | undefined => state.auth?.user;
export const selectUserIncluded = (state: RootState): Included | undefined => state.auth?.included;

export const selectRole = createSelector(
  (state: RootState) => state.auth.user,
  user => user?.relationships.role.data,
);

import React from 'react';
import bn from 'helper/bemnames';
import { Plan } from 'reducers/plan-slice/type';

interface Props {
  plan: Plan;
  onClickHandler: (id: string | number, planName: string) => void;
}

const bem = bn.create('plans');

const PlanItem: React.FC<Props> = ({
  plan,
  onClickHandler,
}: {
  plan: Plan;
  onClickHandler: (id: string | number, planName: string) => void;
}) => {
  return (
    <div className={bem.e('plan-title')} onClick={() => onClickHandler(plan.id, plan.attributes.name)}>
      {plan.attributes.name}
    </div>
  );
};

export default PlanItem;

import React, { useEffect } from 'react';
import { useAppSelector } from 'reducers/store';

import { prepareAuthUri } from 'helper/string';

interface Props {
  title?: string;
}
const AuthPopup: React.FC<Props> = () => {
  const instanceName = useAppSelector<string>(state => state.auth.instanceName);

  useEffect(() => {
    window.location.assign(prepareAuthUri(instanceName));
  }, [instanceName]);
  return <h3>....</h3>;
};

export default AuthPopup;

import React from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { CustomAttribute } from 'reducers/template-slice/type';

interface Props {
  element: CustomAttribute;
  value: string;
  changeHandler: (e: string | React.ChangeEvent, reference: string | undefined, appearance?: string) => void;
}

const Input: React.FC<Props> = ({ element, value, changeHandler }) => {
  const settings = element.attributes.settings && JSON.parse(element.attributes.settings);
  switch (element.attributes.appearance) {
    case 'multiline':
      return (
        <ReactQuill
          theme='snow'
          value={value ? value.toString() : ''}
          onChange={e => {
            changeHandler(e, element.attributes.reference);
          }}
        />
      );
    case 'radio':
      return settings.dataItems.map((item: { id: string; label: string }) => (
        <label key={item.id}>
          <input
            type='radio'
            value={item.id}
            checked={value == item.id}
            name={element.id as string}
            onChange={e => {
              changeHandler(e, element.attributes.reference, element.attributes.appearance);
            }}
          />
          {item.label}
          <span className='checkmark'></span>
        </label>
      ));
    case 'checkbox':
      return settings.dataItems.map((item: { id: string; label: string }) => (
        <label key={item.id}>
          <input
            type='checkbox'
            value={item.id}
            checked={value.includes(item.id)}
            name={item.id}
            onChange={e => {
              changeHandler(e, element.attributes.reference, element.attributes.appearance);
            }}
          />
          {item.label}
          <span className='checkmark'></span>
        </label>
      ));
    case 'singleline':
      return (
        <input
          value={value}
          onChange={e => {
            changeHandler(e, element.attributes.reference);
          }}
        />
      );
    case 'number':
      return (
        <input
          type='number'
          value={value}
          min={settings.minValue}
          max={settings.maxValue}
          onChange={e => {
            changeHandler(e, element.attributes.reference);
          }}
        />
      );
    case 'formula':
      return <input type='text' value={eval(settings.formula)} disabled />;
    case 'date':
      return (
        <input
          type='date'
          required={settings.isRequired}
          min={settings.minDate ? settings.minDate : ''}
          max={settings.maxDate ? settings.maxDate : ''}
          value={moment(value).format('YYYY-MM-DD').toString()}
          onChange={e => {
            changeHandler(e, element.attributes.reference);
          }}
        />
      );
    case 'dropdown':
      return (
        <select
          multiple={'maxCount' in settings}
          onChange={e => {
            changeHandler(e, element.attributes.reference, 'multiselect');
          }}
        >
          {settings.dataItems.map((item: { id: string; label: string }) => (
            <option key={item.id} value={item.id} selected={'maxCount' in settings ? value.includes(item.id) : value == item.id}>
              {item.label}
            </option>
          ))}
        </select>
      );
  }
};

export default Input;

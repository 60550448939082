import { apiClient } from './client';
import { Attributes, FetchUpdatesProps, FetchUpdatesResponse, update } from '../reducers/update-slice/type';

export const fetchUpdatesApi = async ({ page, keyword, sort, size, filter }: FetchUpdatesProps): Promise<FetchUpdatesResponse> => {
  const url = '/updates';
  const { data } = await apiClient.get(url, {
    params: {
      'page[page]': page,
      sort,
      keyword,
      'page[size]': size,
      filter,
      'fields[entityTemplate]': '',
    },
  });
  return data;
};

export const createUpdateApi = async (params: Attributes): Promise<FetchUpdatesResponse> => {
  const url = `/updates`;

  const { data } = await apiClient.post<FetchUpdatesResponse>(url, { data: { attributes: params } });
  return data;
};

export const updateUpdateApi = async (params: update): Promise<FetchUpdatesResponse> => {
  const url = `/updates/${params.id}`;

  const { data } = await apiClient.patch<FetchUpdatesResponse>(url, { data: { attributes: params.attributes } });
  return data;
};

export const deleteUpdateApi = async (params: update): Promise<FetchUpdatesResponse> => {
  const url = `/updates/${params.id}`;

  const { data } = await apiClient.delete<FetchUpdatesResponse>(url);
  return data;
};

export default { fetchUpdatesApi, createUpdateApi, updateUpdateApi, deleteUpdateApi };

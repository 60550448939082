import { AuthState, TokenState } from 'reducers/auth-slice/type';
import { PayloadAction } from '@reduxjs/toolkit';
import { prepareAuthorizationToken } from 'helper/string';
import { apiClient } from 'api/client';

export function signInReducer(state: AuthState, action: PayloadAction<TokenState>): void {
  const tokenState = action.payload;
  apiClient.defaults.headers.common['Authorization'] = prepareAuthorizationToken(tokenState.accessToken, tokenState.tokenType);
  state.tokenState = tokenState;
  state.isLoading = false;
}

import React, { useRef } from 'react';
import TextArea from 'components/core/text-area/TextArea';
import { FormTextAreaProps } from '@fluentui/react-northstar';
import { AcceptIcon, CloseIcon } from 'components/core/icons/Icons';
import FormMessage from 'components/core/form-message/FormMessage';
import bn from 'helper/bemnames';
import classNames from 'classnames';
import { useOnClickOutside } from 'hook/useOnClickOutside';
const bem = bn.create('form-field');
type Props = FormTextAreaProps & {
  isShowActions?: boolean;
  dirty?: boolean;
  actionsProps?: {
    handleSave?: () => void;
    handleBack?: () => void;
  };
};
const FormTextArea: React.FC<Props> = (props: Props) => {
  const { error, errorMessage, actionsProps, isShowActions, dirty, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!dirty) return;
    actionsProps?.handleSave && actionsProps?.handleSave();
  });
  return (
    <div className={bem.b()} ref={ref}>
      <TextArea {...rest} />
      <div className={bem.e('bottom')}>
        {error && <FormMessage error data-testid='error' content={errorMessage} />}
        {isShowActions && (
          <div className={bem.e('actions')} data-testid='actions'>
            <span className={classNames(bem.e('accept'), bem.e('button'))} onClick={actionsProps?.handleSave}>
              <AcceptIcon />
            </span>
            <span className={classNames(bem.e('close'), bem.e('button'))} onClick={actionsProps?.handleBack}>
              <CloseIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default FormTextArea;

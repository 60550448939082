import { Pagination } from 'api/type';
import { IncludedRelationships as UserIncludedRelationships, Included as UserIncluded } from 'reducers/user-slice/type';
export type GoalStatus = 'DRAFT' | 'ARCHIVED' | 'APPROVED';
export enum GoalProgressStatus {
  NOT_STARTED = 'NOT-STARTED',
  OVERDUE = 'OVERDUE',
  ON_TRACK = 'ON-TRACK',
  BEHIND = 'BEHIND',
  COMPLETE = 'COMPLETE',
  WITHIN_THRESHOLD = 'WITHIN-THRESHOLD',
  MANUAL = 'MANUAL',
}
export type GoalType = 'Objective' | 'KPI' | 'Project' | 'Personal' | 'Milestone' | 'Mission';
export type IncludedAttribute = {
  description?: string | null;
  name?: string;
  weight?: string | number;
  is_enabled?: boolean | number;
  first_name?: string;
  last_name?: string;
};
export type IncludedRelationships = {
  role: UserIncludedRelationships<UserIncluded>;
  user?: UserIncludedRelationships<UserIncluded>;
};
export type Included = {
  type: string;
  id: string | number;
  attributes?: IncludedAttribute;
  relationships?: IncludedRelationships;
};
export type IncludedRelationShip<T> = {
  data?: T | null;
};
export type GoalRelationShips = {
  entityTemplate: IncludedRelationShip<Included>;
  unit: IncludedRelationShip<Included>;
  owners: IncludedRelationShip<Included[]>;
  weight: IncludedRelationShip<Included>;
  updates: IncludedRelationShip<Included[]>;
  aligned_focus_areas?: IncludedRelationShip<Included[]>;
  aligned_parent_goals_appro?: IncludedRelationShip<Included[]>;
};
export type GoalAttribute = {
  title: string;
  description: string;
  status: GoalStatus | string;
  initial: string | number;
  target: string | number;
  start_time: string;
  progress_status: GoalProgressStatus | string;
  progress_percentage: number;
  progress: string | number;
  end_time: string;
  settings?: {
    is_private: boolean;
    tracking: {
      autoTrackTargets: boolean;
      completion_criteria: string;
      target_flow: string;
      trackingCurve: string;
      tracking_type: string;
      manualStatus: boolean;
    };
  };
  custom_attributes?: {
    RESERVED_SYSTEM_STATUS?: Array<string>;
  };
};
export type Goal = {
  type: string;
  id: string | number;
  attributes: GoalAttribute;
  relationships: GoalRelationShips;
};
export interface GoalState {
  goals: Goal[];
  goal?: Goal;
  pagination?: Pagination;
  included: Included[];
  includedDetail: Included[];
  isLoading: boolean;
  isLoadingGoal?: boolean;
  error?: string | undefined | null;
}

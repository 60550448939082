import { Button } from '@fluentui/react-northstar';
import { deleteUpdate } from 'helper/feeddispatches';
import React, { useState } from 'react';
import { AppDispatch, useAppDispatch } from 'reducers/store';
import { update } from 'reducers/update-slice/type';
import { dispatchSegmentEvent, SegmentEvent } from 'helper/segment';
interface Props {
  editingItem: update | null;
  goalId: string;
  onCancel: () => void;
  dispatch: AppDispatch;
  isFormValid: () => boolean | undefined;
  onPostHandler: () => void;
}

const Buttons: React.FC<Props> = props => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();

  const handleDelete = () => {
    deleteUpdate(props.dispatch, props.editingItem as update, props.goalId, props.onCancel);
    dispatchSegmentEvent(SegmentEvent.UpdateDeleted, dispatch);
  };

  return (
    <div className='buttons'>
      <div className='btn-group'>
        {props.editingItem ? (
          confirmDelete ? (
            <>
              <Button onClick={handleDelete}>Confirm</Button>
              <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
            </>
          ) : (
            <Button onClick={() => setConfirmDelete(true)}>Delete</Button>
          )
        ) : null}
      </div>
      <div className='btn-group'>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button disabled={!props.isFormValid()} onClick={props.onPostHandler}>
          Post
        </Button>
      </div>
    </div>
  );
};

export default Buttons;

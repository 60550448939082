/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { PlanState } from 'reducers/plan-slice/type';
import { ValidationErrors } from 'type/error';
import { FetchPlansProps, FetchPlansResponse } from 'api/type';
import { fetchPlansApi } from 'api/plan';
import { StatusCode } from 'type/error';

export const fetchPlansThunk = createAsyncThunk<
  FetchPlansResponse,
  FetchPlansProps,
  {
    rejectValue: ValidationErrors;
  }
>('plan/fetchPlansPlanner', async option => {
  try {
    const response = await fetchPlansApi({ ...option });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchPlansReducer = (builder: ActionReducerMapBuilder<PlanState>): void => {
  builder.addCase(fetchPlansThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.plans = payload.data;
    state.pagination = payload.meta.pagination;
    state.included = payload.included;
  });
  builder.addCase(fetchPlansThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchPlansThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

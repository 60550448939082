import axios from 'axios';
import { prepareInstanceURL } from 'helper/string';

const instanceRegionCache: Map<string, string> = new Map<string, string>();
const leadIdCache: Map<string, number | null> = new Map<string, number | null>();

/**
 * Get the region where an instance is hosted.
 *
 * @param rawInstanceName
 */
export const fetchInstanceRegion = async (
  rawInstanceName: string,
): Promise<{
  instanceRegion?: string;
  leadId?: string;
}> => {
  if (!instanceRegionCache.has(rawInstanceName) || !leadIdCache.has(rawInstanceName)) {
    const instanceURL = prepareInstanceURL(rawInstanceName);

    const response = await axios.get<{
      client: {
        region: string;
        lead_id: number | null;
      };
    }>(`${instanceURL}/api/v2/clients/self`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    instanceRegionCache.set(rawInstanceName, response.data?.client.region);
    leadIdCache.set(rawInstanceName, response.data?.client.lead_id || null);
  }

  const leadId = leadIdCache.get(rawInstanceName);

  return {
    instanceRegion: instanceRegionCache.get(rawInstanceName),
    leadId: leadId ? '' + leadId : '',
  };
};

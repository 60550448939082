import { createSelector } from '@reduxjs/toolkit';
import { User } from 'reducers/user-slice/type';
import { RootState } from 'reducers/rootReducer';
import { Item as ItemDropdown } from 'components/core/dropdown/type';
export const selectUsers = (state: RootState): User[] => state.user.users;

export const selectUsersForDropdown = createSelector(
  (state: RootState) => state.user.users,
  (users): ItemDropdown<User>[] =>
    users.map((user: User) => ({
      header: `${user.attributes.first_name} ${user.attributes.last_name}`,
      data: user,
    })),
);

export const selectError = (state: RootState): string | undefined | null => state.user.error;

export const selectIsLoading = (state: RootState): boolean => state.user.isLoading;

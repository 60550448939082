import React from 'react';
import bn from 'helper/bemnames';
const bem = bn.create('page-title');
interface Props {
  title: string;
  className?: string;
}

const PageTitle: React.FunctionComponent<Props> = ({ title, className }: Props) => {
  return <h1 className={bem.b([className])}>{title}</h1>;
};
export default PageTitle;

import React from 'react';
import { LockIcon } from 'components/core/icons/Icons';
import { DialogFooter, Flex } from '@fluentui/react-northstar';
import BaseModal, { BaseModalProps } from 'components/modals/base-modal/Base';
import Button from 'components/core/button/Button';
import Input from 'components/core/input/Input';
import bn from 'helper/bemnames';
import * as microsoftTeams from '@microsoft/teams-js';

const bem = bn.create('sign-in-modal');

type Props = BaseModalProps & {
  instanceName: string;
  errorMessage: string;
  setInstanceName: (value: string) => void;
  setErrorMessage: (value: string) => void;
};

const SignInModal: React.FC<Props> = (props: Props) => {
  const { instanceName, setInstanceName, errorMessage, setErrorMessage, className, ...rest } = props;
  const onClose = () => {
    microsoftTeams.authentication.notifyFailure('closed');
    window.close();
  };
  return (
    <BaseModal
      {...rest}
      closeOnOutsideClick={false}
      className={bem.b([className])}
      footer={
        <DialogFooter className={bem.e('footer')}>
          <Flex column hAlign='center'>
            <Button
              key='submit'
              primary
              buttonColor='primary'
              disabled={!instanceName}
              data-testid='btn-submit-sign-in'
              content='Continue'
              className={bem.e('btn-submit')}
              onClick={props.onConfirm}
            />
          </Flex>
        </DialogFooter>
      }
      content={
        <div className={bem.e('modal-content')}>
          <div className={bem.e('lock')}>
            <LockIcon className={bem.e('lock-icon')} />
          </div>
          <div className={bem.e('content')}>
            <h2 className={bem.e('title')}>Login to Cascade</h2>
            <p className={bem.e('description')}>Enter your instance name to start</p>
            <span className={bem.e('sub-des')}>
              Usually this will be in the form of
              <br /> ‘<b>yourcompanyname</b>.executestrategy.net’.
            </span>
            <div className={bem.e('input-container')}>
              <div className={bem.e('input-content')}>
                <Input
                  className={bem.e('input')}
                  placeholder=''
                  value={instanceName}
                  onChange={(event, data) => {
                    setErrorMessage('');
                    setInstanceName(data?.value ?? '');
                  }}
                  onKeyPress={event => {
                    if (event.charCode === 13 && props.onConfirm) {
                      props.onConfirm(event);
                    }
                  }}
                  error={!!errorMessage}
                />
                {errorMessage && <span className={bem.e('error-message')}>{errorMessage}</span>}
              </div>
              <span className={bem.e('domain')}>.executestrategy.net</span>
            </div>
            <p className={bem.e('sign-up-text')}>
              Don&apos;t have a Cascade account yet?{' '}
              <a href='https://www.cascade.app/trial' onClick={onClose} target='__blank'>
                Click here
              </a>{' '}
              to sign up
            </p>
          </div>
        </div>
      }
    ></BaseModal>
  );
};
export default SignInModal;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { fetchProfileApi } from 'api/auth';
import { FetchProfileResponse } from 'api/type';
import { AuthState } from 'reducers/auth-slice/type';
import { StatusCode } from 'type/error';

export const fetchProfileThunk = createAsyncThunk<
  FetchProfileResponse,
  void,
  {
    rejectValue: ValidationErrors;
  }
>('auth/fetchProfile', async () => {
  try {
    const response = await fetchProfileApi();

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchProfileReducer = (builder: ActionReducerMapBuilder<AuthState>): void => {
  builder.addCase(fetchProfileThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.user = payload.data;
    state.included = payload.included;
  });
  builder.addCase(fetchProfileThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchProfileThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import { createSlice } from '@reduxjs/toolkit';
import { fetchPlansReducer } from './reducers/fetchPlansReducer';
import { fetchGoalsByPlanReducer } from './reducers/fetchGoalsByPlanReducer';

import { PlanState } from './type';
const initialState: PlanState = { plans: [], isLoading: false, included: [], goalsByPlan: [] };

const { reducer, actions } = createSlice({
  name: 'weight',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchPlansReducer(builder);
    fetchGoalsByPlanReducer(builder);
  },
});
export { reducer, actions as planAction };

import { themeNames } from '@fluentui/react-teams';
// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
export function initTeamsTheme(theme: string | undefined): themeNames {
  switch (theme) {
    case 'dark':
      return themeNames.Dark;
    case 'contrast':
      return themeNames.HighContrast;
    default:
      return themeNames.Default;
  }
}

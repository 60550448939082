/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import Input from 'components/core/input/Input';
import { FormInputProps } from '@fluentui/react-northstar';
import { AcceptIcon, CloseIcon } from 'components/core/icons/Icons';
import FormMessage from 'components/core/form-message/FormMessage';
import bn from 'helper/bemnames';
import classNames from 'classnames';
import { useOnClickOutside } from 'hook/useOnClickOutside';
const bem = bn.create('form-field');
type Props = FormInputProps & {
  isShowActions?: boolean;
  dirty?: boolean;
  actionsProps?: {
    handleSave?: () => void;
    handleBack?: () => void;
  };
};
const FormInput: React.FC<Props> = (props: Props) => {
  const { error, errorMessage, actionsProps, isShowActions, dirty, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, (event: any) => {
    if (!dirty) return;
    if (event && event.target && event.target.classList && event.target.classList.contains('stop-click')) {
      return;
    }
    actionsProps?.handleSave && actionsProps?.handleSave();
  });
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m('read-only')]: props.readOnly,
      })}
      ref={ref}
    >
      <Input {...rest} />
      <div className={bem.e('bottom')}>
        {error && <FormMessage data-testid='error' error content={errorMessage} />}
        {isShowActions && (
          <div className={bem.e('actions')} data-testid='actions'>
            <span className={classNames(bem.e('accept'), bem.e('button'))} onClick={actionsProps?.handleSave}>
              <AcceptIcon />
            </span>
            <span className={classNames(bem.e('close'), bem.e('button'))} onClick={actionsProps?.handleBack}>
              <CloseIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default FormInput;

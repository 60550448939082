import React from 'react';
import { GoalType } from 'reducers/goal-slice/type';
import bn from 'helper/bemnames';
import classNames from 'classnames';
const bem = bn.create('goal-type');
interface Props {
  type: GoalType;
}
export const CLASS_COLORS = {
  Objective: 'objective',
  KPI: 'kpi',
  Project: 'project',
  Personal: 'personal',
  Milestone: 'milestone',
  Mission: 'mission',
  Goal: 'goal',
};
const Type: React.FunctionComponent<Props> = ({ type }: Props) => {
  return <div className={classNames(bem.b(), bem.m(CLASS_COLORS[type]))}>{type}</div>;
};
export default Type;

import { createSlice } from '@reduxjs/toolkit';
import { fetchGoalsReducer } from './reducers/fetchGoalsReducer';
import { fetchGoalReducer } from './reducers/fetchGoalReducer';
import { createGoalReducer } from './reducers/createGoalReducer';
import { updateGoalReducer } from './reducers/updateGoalReducer';

import { GoalState } from './type';
import { authAction } from 'reducers/auth-slice';
const initialState: GoalState = { goals: [], isLoading: false, included: [], goal: undefined, includedDetail: [], isLoadingGoal: false };

const { reducer, actions } = createSlice({
  name: 'goal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(authAction.signOutUser, () => initialState);
    fetchGoalsReducer(builder);
    fetchGoalReducer(builder);
    createGoalReducer(builder);
    updateGoalReducer(builder);
  },
});
export { reducer, actions as goalAction, initialState };

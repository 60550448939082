import React, { useState } from 'react';
import { ArrowSortIcon, ArrowUpIcon, ArrowDownIcon, Image } from '@fluentui/react-northstar';
import { isMobile } from 'react-device-detect';
import bn from 'helper/bemnames';
import classNames from 'classnames';

interface GoalHeaderProps {
  handleSort: (field: string, join?: null | string) => void;
  sort: string;
}
const bem = bn.create('goals');

const GoalHeader = (props: GoalHeaderProps): { items: { content: JSX.Element; key: string; className: string }[] } => {
  const { handleSort, sort } = props;
  const header = {
    items: [
      {
        content: (
          <Header
            title='Goal Title'
            onClick={() => handleSort('title')}
            direction={sort == 'title' || sort == '-title' ? (sort == 'title' ? 'default' : 'desc') : 'default'}
          />
        ),
        key: 'title',
        className: bem.e('width-title'),
      },
      {
        content: (
          <Header
            title='Goal Type'
            onClick={() => handleSort('template', 'entityTemplate:left')}
            direction={sort == 'template' || sort == '-template' ? (sort == 'template' ? 'asc' : 'desc') : 'default'}
          />
        ),
        key: 'type',
        className: bem.e('width-type'),
      },
      {
        content: (
          <Header
            title='Owner'
            onClick={() => handleSort('owner', 'userRoleView:left')}
            direction={sort == 'owner' || sort == '-owner' ? (sort == 'owner' ? 'asc' : 'desc') : 'default'}
          />
        ),
        key: 'owner',
        className: bem.e('width-owner'),
      },
      {
        content: (
          <Header
            title='Due date'
            onClick={() => handleSort('end_time')}
            direction={sort == 'end_time' || sort == '-end_time' ? (sort == 'end_time' ? 'asc' : 'desc') : 'default'}
          />
        ),

        key: 'date',
        className: bem.e('width-date'),
      },
      {
        content: <Header title='Status' allowed={false} />,
        key: 'status',
        className: bem.e('width-status'),
      },
      {
        content: (
          <Header
            title='Weight'
            onClick={() => handleSort('weight', 'weight:left')}
            direction={sort == 'weight' || sort == '-weight' ? (sort == 'weight' ? 'asc' : 'desc') : 'default'}
          />
        ),
        key: 'weight',
        className: bem.e('width-weight'),
      },
    ],
  };

  return header;
};
interface HeaderProps {
  title: string;
  onClick?: () => void;
  direction?: string;
  allowed?: boolean;
}
const Header: React.FC<HeaderProps> = props => {
  const { title, onClick, direction, allowed } = props;

  const [onHover, setOnHover] = useState(false);

  const isVisible = (onHover && allowed) || direction == 'asc' || direction == 'desc';
  if (!isMobile) {
    return (
      <div onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
        <div className={bem.e('table-header-image-container')}>
          {isVisible && (
            <Image
              src={direction == 'asc' ? 'images/Up.svg' : direction == 'desc' ? 'images/Down.svg' : 'images/Default.svg'}
              onClick={onClick}
              className={bem.e('table-header-image')}
            />
          )}
        </div>
        <div className={bem.e('table-header-title')}>{title}</div>
      </div>
    );
  } else {
    return (
      <div onClick={onClick} className={classNames(bem.e('table-header-title'), bem.e('table-header-title-mobile'))}>
        {title}
        <span style={{ marginLeft: '5px' }}>
          {allowed &&
            (direction == 'asc' ? (
              <ArrowUpIcon size='smallest' />
            ) : direction == 'desc' ? (
              <ArrowDownIcon size='smallest' />
            ) : (
              <ArrowSortIcon size='smaller' />
            ))}
        </span>
      </div>
    );
  }
};
Header.defaultProps = { allowed: true };
export default GoalHeader;

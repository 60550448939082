import * as React from 'react';
import { Dropdown as DropdownBase, DropdownProps } from '@fluentui/react-northstar';
import bn from 'helper/bemnames';
const bem = bn.create('dropdown');
const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const [open, setOpen] = React.useState<boolean | undefined>(false);
  const { search } = props;
  let dropdownProps = props;
  if (search) {
    dropdownProps = {
      ...props,
      open: open,
      onOpenChange: (_e, data) => {
        setOpen(data.open);
      },
      searchInput: {
        onClick: () => {
          if (!open) {
            setOpen(true);
          }
        },
      },
    };
  }

  return <DropdownBase fluid {...dropdownProps} className={bem.b()} />;
};
export default Dropdown;

import { Config } from './../../../reducers/config-slice/type';
import { GoalProgressStatus } from './../../../reducers/goal-slice/type';
import * as Yup from 'yup';

import { split, trim } from 'lodash';

import { Item as DropdownItem } from 'components/core/dropdown/type';

import { FormGoalProps } from 'api/type';

import { Goal, Included } from 'reducers/goal-slice/type';

import { Template } from 'reducers/template-slice/type';
import { Weight } from 'reducers/weight-slice/type';
import { User } from 'reducers/user-slice/type';
import { Plan, PlanIncluded } from 'reducers/plan-slice/type';
export enum ActionMode {
  'Edit' = 'edit',
  'Create' = 'create',
  'View' = 'view',
}
export interface GoalFormProps {
  id?: string | number;
  title: string;
  start_time: Date;
  end_time: Date;
  progress: string | number;
  progress_status: string | number;
  progress_percentage: string | number;
  aligned_to?: DropdownItem<PlanIncluded | Goal>;
  settings?: GoalSettings | string;

  owner?: DropdownItem<Included>;
  weight?: DropdownItem<Included>;
  entityTemplate?: DropdownItem<Included>;
  description?: string;
  plan?: DropdownItem<Plan>;
  custom_attributes?: GoalCustomAttributes | string;

  statusText: GoalProgressStatus | string;
}
export type GoalCustomAttributes = {
  RESERVED_SYSTEM_STATUS?: Array<string>;
};
export interface GoalProps {
  user?: User;
  goal?: Goal;
  desc?: string;
  loading?: boolean;
  config: Config[];
  fetchGoalsByPlan: ({ id, keyword }: { id: string | number; keyword?: string }, callback?: (response: Goal[]) => void) => void;
  fetchUsers: (keyword?: string) => void;
  fetchPlans: (keyword?: string) => void;
  onSubmit: (values: FormGoalProps, callback?: () => void) => void;
  action: ActionMode;
  instanceName: string;
  templatesOptions: DropdownItem<Template>[];
  weightsOptions: DropdownItem<Weight>[];
  usersOptions: DropdownItem<User>[];
  plansOptions: DropdownItem<Plan>[];
  goalsByPlanOptions?: DropdownItem<Goal>[];
  initialValues: GoalFormProps;
}
export type GoalSettings = {
  is_private: boolean;
  tracking: {
    autoTrackTargets: boolean;
    completion_criteria: string;
    target_flow: string;
    trackingCurve: string;
    tracking_type: string;
    manualStatus: boolean;
  };
};
export const validationSchemaCreate = Yup.object().shape({
  id: Yup.string().notRequired(),
  title: Yup.string()
    .test(
      'min-words',
      'Title field requires at least two words',
      (value: string | undefined): boolean => split(trim(value), ' ').length >= 2,
    )
    .max(280, 'Max length 280 characters')
    .required('Title field requires at least two words'),
  entityTemplate: Yup.mixed().required('Goal type field is required'),
  start_time: Yup.date().required('Start date field is required'),
  end_time: Yup.date().required('End date field is required'),
  weight: Yup.mixed().required('Weight field is required'),
  owner: Yup.mixed().required('Owner field is required'),
  description: Yup.string().notRequired().max(65535, 'Max length 65535 characters').nullable(),
  progress: Yup.number().typeError('Progress must be a number').max(100, 'Progress max is 100').notRequired(),
  progress_status: Yup.string().notRequired(),
  progress_percentage: Yup.string().notRequired(),
  plan: Yup.mixed().required('Plan field is required'),
  aligned_to: Yup.mixed().required('Parent Goal or Focus area field is required'),
});

export const validationSchemaEdit = Yup.object().shape({
  id: Yup.string().notRequired(),
  title: Yup.string()
    .test(
      'min-words',
      'Title field requires at least two words',
      (value: string | undefined): boolean => split(trim(value), ' ').length >= 2,
    )
    .max(280, 'Max length 280 characters')
    .required('Title field requires at least two words'),
  entityTemplate: Yup.mixed().required('Goal type field is required'),
  start_time: Yup.date().required('Start date field is required'),
  end_time: Yup.date().required('End date field is required'),
  weight: Yup.mixed().required('Weight field is required'),
  owner: Yup.mixed().required('Owner field is required'),
  description: Yup.string().notRequired().max(65535, 'Max length 65535 characters').nullable(),
  progress: Yup.number().typeError('Progress must be a number').max(100, 'Progress max is 100').notRequired(),
  progress_status: Yup.string().notRequired(),
  progress_percentage: Yup.string().notRequired(),
  plan: Yup.mixed().notRequired(),
  aligned_to: Yup.mixed().notRequired(),
});

import { createSlice } from '@reduxjs/toolkit';
import { createUpdatesReducer } from './reducers/createUpdateReducer';
import { deleteUpdatesReducer } from './reducers/deleteUpdatesReducer';
import { fetchUpdatesReducer } from './reducers/fetchUpdatesReducer';
import { updateUpdatesReducer } from './reducers/updateUpdateReducer';
import { UpdateState } from './type';

const initialState: UpdateState = { updates: [], isLoading: false, included: [] };

const { reducer, actions } = createSlice({
  name: 'update',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchUpdatesReducer(builder);
    createUpdatesReducer(builder);
    updateUpdatesReducer(builder);
    deleteUpdatesReducer(builder);
  },
});
export { reducer, actions as planAction };

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import bn from 'helper/bemnames';
import { AppDispatch, useAppSelector, useAppDispatch } from 'reducers/store';
import { Plan } from 'reducers/plan-slice/type';
import { selectPlans, selectIsLoading } from 'reducers/planner-slice/selector';
import { fetchGoalsByPlanThunk } from 'reducers/planner-slice/reducers/fetchGoalsByPlanReducer';
import { unwrapResult } from '@reduxjs/toolkit';
import { Goal } from 'reducers/goal-slice/type';
import PlanItem from '../plan-item/PlanItem';
import { fetchPlansThunk } from 'reducers/planner-slice/reducers/fetchPlansReducer';
// import GoalsScreen from 'journey/profile/goal-list-screen/GoalsScreen';
import Loader from 'components/core/loader/Loader';
import PlanView from '../plan-view/PlanView';
import TopBar from 'components/top-bar/TopBar';
import GoalForm from 'journey/profile/goal-form/GoalFormScreen';
import { ActionMode } from 'components/forms/goal/type';
import { DropdownProps, Flex } from '@fluentui/react-northstar';
import * as criteria from '../../../helper/criteria';
import { Item as DropdownItem } from 'components/core/dropdown/type';
import DueFilterDropDown, { dueValues, getDueDateFilterRange } from 'components/core/due-filter-dropdown/DueFilterDropDown';

const bem = bn.create('plans');

interface Props {
  title?: string;
}

const PlansScreen: React.FC<Props> = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const plans = useAppSelector<Plan[]>(selectPlans);
  const [showGoals, setShowGoals] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(undefined);
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(undefined);
  const loading = useAppSelector<boolean>(selectIsLoading);
  const [isOpenFormModal, setIsOpenFormModal] = useState<boolean>(false);
  const [formAction, setFormAction] = useState<ActionMode>(ActionMode.Create);
  const [decodedFilter, setDecodedFilter] = useState<criteria.filter | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [sort, setSort] = useState('title');

  useEffect(() => {
    if (selectedPlanId) {
      dispatch(
        fetchGoalsByPlanThunk({
          id: selectedPlanId,
          sort: sort,
          // join: join,
          decodedFilter: decodedFilter,
        }),
      );
    }
  }, [dispatch, sort, decodedFilter]);

  useEffect(() => {
    dispatch(
      fetchPlansThunk({
        include: 'focusAreas',
        // size: '10',
      }),
    );
  }, [dispatch]);

  const fetchGoalsByPlan = (
    { id }: { id: string | number; keyword?: string; decodedFilter?: criteria.filter },
    callback?: (data: Goal[]) => void,
  ) => {
    let params: { id: string | number; sort: string; decodedFilter?: criteria.filter } = { id, sort };
    if (decodedFilter) params = { ...params, decodedFilter: decodedFilter };
    dispatch(fetchGoalsByPlanThunk(params))
      .then(unwrapResult)
      .then(response => {
        if (callback) callback(response.data);
      });
  };

  const handleClick = (id: string | number, planName: string) => {
    fetchGoalsByPlan({ id });
    setShowGoals(true);
    setSelectedPlan(planName);
    setSelectedPlanId(id.toString());
  };

  const addGoalHandler = () => {
    setFormAction(ActionMode.Create);
    setIsOpenFormModal(true);
  };

  useEffect(() => {
    if (selectedPlanId && decodedFilter) {
      fetchGoalsByPlan({ id: selectedPlanId, decodedFilter: decodedFilter });
    } else if (selectedPlanId) {
      fetchGoalsByPlan({ id: selectedPlanId });
    }
  }, [decodedFilter]);

  const handleDueFilterChange = (e: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => {
    if (data.value && typeof data.value === 'object') {
      const value = data.value as DropdownItem<dueValues>;
      const dates = getDueDateFilterRange(value);
      if (dates) {
        const filter = criteria.build(
          criteria.operator.AND,
          {
            field: 'end_time',
            operator: criteria.operator.GREATHER_THAN_EQUAL,
            value: dates[0],
          },
          {
            field: 'end_time',
            operator: criteria.operator.LESS_THAN_EQUAL,
            value: dates[1],
          },
        );
        setSelectedFilter(value.header);
        setDecodedFilter(filter);
      } else {
        setDecodedFilter(null);
        setSelectedFilter(null);
      }
    } else {
      setDecodedFilter(null);
      setSelectedFilter(null);
    }
  };

  const handleGoBack = () => {
    setShowGoals(false);
    setDecodedFilter(null);
    setSelectedFilter(null);
    setSort('title');
  };

  const handleSort = (field: string) => {
    // setPage(1);
    // if (join) {
    //   setJoin(join);
    // } else {
    //   setJoin('');
    // }
    //for descending order
    if (sort === field) {
      field = '-' + field;
    }
    setSort(field);
  };

  return (
    <>
      <div className={bem.b()}>
        <TopBar
          title={!showGoals ? 'My Plans' : selectedPlan || ''}
          addGoalHandler={addGoalHandler}
          handleGoBack={handleGoBack}
          showGoals={showGoals}
        />
        {!showGoals ? (
          <div>
            {loading ? (
              <Loader loading={true} fluid label='Fetching your plans...' />
            ) : (
              <div>
                {plans.map(plan => (
                  <PlanItem key={plan.id} onClickHandler={handleClick} plan={plan} />
                ))}
              </div>
            )}
          </div>
        ) : (
          <>
            {loading ? (
              <Loader loading={true} fluid label='Loading...' />
            ) : (
              <>
                {selectedPlan && (
                  <>
                    <div className={bem.e('filters-container')}>
                      <Flex gap='gap.small' hAlign='end'>
                        <DueFilterDropDown value={selectedFilter} onChange={handleDueFilterChange} styles={{ width: '161px' }} />
                      </Flex>
                    </div>
                    <PlanView setFormAction={setFormAction} setIsOpenFormModal={setIsOpenFormModal} handleSort={handleSort} sort={sort} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <GoalForm
        modalProps={{
          open: isOpenFormModal,
          onCancel: () => setIsOpenFormModal(false),
        }}
        onCloseModal={() => setIsOpenFormModal(false)}
        formProps={{
          action: formAction,
        }}
        showGoals={showGoals}
        planId={selectedPlanId}
      />
    </>
  );
};

export default PlansScreen;

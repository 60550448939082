import { SEGMENT_INTEGRATION_ID } from 'config/environment';

import { segmentAnalyticsThunk } from 'reducers/auth-slice/reducers/segmentAnalyticsReducer';
import { AppDispatch } from 'reducers/store';

export enum SegmentEvent {
  UpdateUpdated = 'Update Edited',
  UpdateCreated = 'Update Created',
  UpdateDeleted = 'Update Deleted',
  OpenInCascade = 'Open in Cascade',
}

export const dispatchSegmentEvent = (e: SegmentEvent, dispatch: AppDispatch): void => {
  dispatch(
    segmentAnalyticsThunk({
      event: e,
      options: {
        source_invoker: SEGMENT_INTEGRATION_ID,
      },
    }),
  );
};

import { TokenState } from 'reducers/auth-slice/type';
import { FormGoalProps as FormGoalApiProps } from 'api/type';
import moment from 'moment';
import { GoalFormProps } from 'components/forms/goal/type';

export function roundedNumber(round: number, number: number): number {
  return Number(number.toFixed(round));
}
export function getGoalHubPage(instanceName: string, goalId: string | number): string {
  return `//${instanceName}.executestrategy.net/user/goals/${goalId}`;
}
export function formatTokenState(data: unknown): TokenState {
  const obj = typeof data === 'string' ? JSON.parse(data) : data;
  return {
    accessToken: obj?.access_token,
    expiresIn: obj?.expires_in,
    tokenType: obj?.token_type,
    refreshToken: obj?.refresh_token,
    instance: obj?.instance,
    instanceRegion: obj?.instanceRegion,
    leadId: obj?.leadId,
  };
}
export function formatFormGoalProps(data: GoalFormProps): FormGoalApiProps {
  const relationships = {
    entity_template: {
      data: {
        id: data.entityTemplate && data.entityTemplate?.data ? data.entityTemplate?.data.id : '',
        type: 'entity_template',
      },
    },
    weight: {
      data: {
        id: data.weight && data.weight?.data ? data.weight?.data.id : '',
        type: 'weight',
      },
    },
    owners: {
      data: [
        {
          id:
            data.owner?.data?.relationships?.role?.data && data.owner?.data?.relationships?.role?.data.id
              ? data.owner?.data?.relationships?.role?.data.id
              : '',
          type: 'role',
        },
      ],
    },
    aligned_focus_areas: {
      data:
        data.aligned_to && data.aligned_to.data?.type === 'focusArea'
          ? [
              {
                id: data.aligned_to.data.id,
                type: 'focus_area',
              },
            ]
          : [],
    },
    aligned_parent_goals_appro: {
      data:
        data.aligned_to && data.aligned_to.data?.type === 'goal'
          ? [
              {
                id: data.aligned_to.data.id,
                type: 'goal',
              },
            ]
          : [],
    },
  };
  const attributesParams: { progress?: string | number } = {};
  if (data.id) {
    attributesParams.progress = data.progress ? Number(data.progress) : 0;
  }
  const formatted = {
    type: 'goal',
    attributes: {
      ...attributesParams,
      title: data.title,
      start_time: `${moment(data.start_time).startOf('day').toISOString()}`,
      end_time: `${moment(data.end_time).endOf('day').toISOString()}`,
      description: data.description,
      settings: data.settings,
      custom_attributes: data.custom_attributes,
    },
    relationships,
  };

  if (!data.custom_attributes || data.custom_attributes == '' || Object.keys(data.custom_attributes).length <= 0) {
    delete formatted.attributes.custom_attributes;
  }
  if (!data.settings || data.settings == '' || Object.keys(data.settings).length <= 0) {
    delete formatted.attributes.settings;
  } else {
    formatted.attributes.settings = JSON.stringify(data.settings);
  }
  return {
    data: formatted,
  };
}

export function convertObjectToBase64(obj: Record<string, unknown>): string {
  const objJsonStr = JSON.stringify(obj);
  return btoa(objJsonStr);
}

export function formatNumber(target: string | number): number {
  return Number(target);
}

/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { UserState } from 'reducers/user-slice/type';
import { ValidationErrors } from 'type/error';
import { FetchUsersResponse, FetchUsersProps } from 'api/type';
import { fetchUsersApi } from 'api/user';
import { StatusCode } from 'type/error';
import { convertObjectToBase64 } from 'helper/common';

export const fetchUsersThunk = createAsyncThunk<
  FetchUsersResponse,
  FetchUsersProps,
  {
    rejectValue: ValidationErrors;
  }
>('user/fetchUsers', async option => {
  try {
    const filter = convertObjectToBase64({
      operator: 'and',
      value: [
        { field: 'is_restricted', operator: '=', value: false },
        { field: 'is_archived', operator: '=', value: false },
      ],
    });
    const response = await fetchUsersApi({ ...option, filter, include: 'role' });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchUsersReducer = (builder: ActionReducerMapBuilder<UserState>): void => {
  builder.addCase(fetchUsersThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.users = payload.data;
    state.pagination = payload.meta.pagination;
  });
  builder.addCase(fetchUsersThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchUsersThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

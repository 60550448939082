import React from 'react';
import bn from 'helper/bemnames';
import FeedList from './FeedList/FeedList';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectUpdates, selectIsLoading as selectIsLoadingUpdate } from 'reducers/update-slice/selector';
import { AppDispatch, useAppDispatch, useAppSelector } from 'reducers/store';
import { fetchUpdatesThunk } from 'reducers/update-slice/reducers/fetchUpdatesReducer';
import { Goal } from 'reducers/goal-slice/type';
import { Included, update } from 'reducers/update-slice/type';
import { Template, CustomAttribute, CustomTemplate } from 'reducers/template-slice/type';
import { selectAttributes, selectTemplates } from 'reducers/template-slice/selector';
import FeedInput from './FeedInput/FeedInput';
import FeedButton from './FeedTabs/FeedButton';
import Loader from 'components/core/loader/Loader';
import { selectIsLoadingGoal } from 'reducers/goal-slice/selector';

const bem = bn.create('feed');

interface Props {
  goal?: Goal;
  instanceName: string;
  bottomHit: boolean;
}

const Feed: React.FC<Props> = props => {
  const dispatch: AppDispatch = useAppDispatch();
  const updates = useAppSelector(selectUpdates);
  const attributes = useAppSelector(selectAttributes);
  const selectedTemplates = useAppSelector(selectTemplates);
  const [activity, setActivity] = useState<update[]>();
  const [included, setIncluded] = useState<Included[]>();
  const [templates, setTemplates] = useState<Template[]>();
  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[] | []>();
  const [updateEntityTemplates, setUpdateEntityTemplates] = useState<Template[]>();
  const [selectedUpdate, setSelectedUpdate] = useState<string | CustomTemplate>('none');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editingItem, setEditingItem] = useState<update | null>(null);
  const loadingUpdate = useAppSelector<boolean>(selectIsLoadingUpdate);
  const loadingGoal = useAppSelector<boolean>(selectIsLoadingGoal);

  useEffect(() => {
    setCustomAttributes(attributes.filter(att => att.type === 'attribute'));
  }, [attributes]);

  useEffect(() => {
    if (props.goal?.relationships.updates.data && props.goal?.relationships.updates.data?.length > 0) {
      dispatch(
        fetchUpdatesThunk({
          page: page,
          goal: props.goal as Goal,
        }),
      );
    }
  }, [dispatch, page]);

  useEffect(() => {
    if (props.bottomHit === true && page < totalPages) setPage(page + 1);
  }, [props.bottomHit]);

  useEffect(() => {
    if (updates.updates) {
      if (updates.pagination) setTotalPages(updates.pagination?.total_pages);
      const feed = [...updates.updates];
      setIncluded([...updates.included]);
      setTemplates(selectedTemplates);
      setActivity(feed);
      const updatesTemplates = selectedTemplates.filter(t => t.attributes.entity_type === 'update' && t.attributes.name !== 'Update');
      setUpdateEntityTemplates(updatesTemplates);
    }
  }, [updates.updates]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const foundSelectedUpdate = updateEntityTemplates?.find(t => t.id === e.target.value);
    setEditingItem(null);
    setSelectedUpdate(foundSelectedUpdate as CustomTemplate);
  };

  const onStartEdit = (item: update) => {
    const foundSelectedUpdate = updateEntityTemplates?.find(t => t.id === item.relationships.entityTemplate.data.id);
    setEditingItem(item);
    setSelectedUpdate(foundSelectedUpdate as CustomTemplate);
  };
  return (
    <>
      <div className={bem.e('tabs')}>
        {loadingGoal || loadingUpdate ? <Loader loading={true} fluid /> : null}
        <p>Activity</p>
        <FeedButton
          selectedUpdate={selectedUpdate}
          updateEntityTemplates={updateEntityTemplates}
          goal={props.goal}
          handleChange={handleChange}
        />
      </div>
      {selectedUpdate !== 'none' ? (
        <div className={bem.e('feedinput')}>
          <FeedInput
            onCancel={() => setSelectedUpdate('none')}
            selectedUpdate={selectedUpdate as CustomTemplate}
            goalId={props.goal?.id as string}
            editingItem={editingItem}
          />
        </div>
      ) : null}
      <div className={bem.e('feedlist')}>
        {
          <FeedList
            content={activity}
            included={included}
            attributes={customAttributes}
            templates={templates}
            instanceName={props.instanceName}
            onStartEdit={onStartEdit}
            goalId={props.goal?.id as string}
          />
        }
      </div>
    </>
  );
};

export default Feed;

export const routes = {
  WELCOME: '/welcome',
  AUTH_CALLBACK: '/auth/callback',
  TAB: '/tab',
  PRIVACY: '/privacy',
  TERMSOFUSE: '/termsofuse',
  AUTH_POPUP: '/auth-popup',
  BOT_AUTH_POPUP: '/bot-auth-popup',
  BOT_ERROR_POPUP: '/bot-error-popup',
  BOT_GOAL_CONFIRM_POPUP: '/bot-goal-confirm-popup',
  GOALS_CREATE: '/goals/create',
};

import React from 'react';
import { Template, CustomAttribute } from 'reducers/template-slice/type';
import { Attributes, Included, RelationshipItem, update } from 'reducers/update-slice/type';
import moment from 'moment';
import { AppDispatch } from 'reducers/store';
import { fetchFileThunk } from 'reducers/file-slice/reducers/fetchFileReducer';

export const getTemplateName = (id: string | number, templates: Template[] | undefined): string | undefined => {
  if (templates) {
    const entityTemplate = templates.find(t => t.id === id && t.type === 'entityTemplate');
    const templateName = entityTemplate?.attributes.name;
    return templateName;
  }
};

export const getAttachedFile = async (
  included: Included[] | undefined,
  instanceName: string,
  attachedFile: RelationshipItem,
  dispatch: AppDispatch,
): Promise<JSX.Element | null | undefined> => {
  let file: Included | undefined = undefined;
  if (included && attachedFile.data) {
    file = included.find(f => f.type === 'file' && attachedFile.data.id === f.id);
  }
  if (file) {
    return dispatch(fetchFileThunk(file)).then(rta => {
      const link = (rta.payload as unknown) as string;
      return (
        <a target='_blank' href={link} rel='noreferrer'>
          {file?.attributes.filename}
        </a>
      );
    });
  } else {
    return null;
  }
};

export const getCustomAttributes = (item: [string, string], attributes: CustomAttribute[] | undefined): JSX.Element | null => {
  const customAtt = item[0];
  let value = item[1];
  const customStyles = { color: '', backgroundColor: '' };
  if (attributes) {
    const actualAtt = attributes.find(attr => attr.attributes.reference === customAtt);
    if (actualAtt?.attributes.settings) {
      const settings = JSON.parse(actualAtt?.attributes.settings);
      customStyles.color = settings.color;
      customStyles.backgroundColor = settings.backgroundColor;
      if (Array.isArray(value) && value.length > 0) {
        const { label } = settings.dataItems.find((set: { id: string; label: string }) => set.id === value[0]);
        value = label;
      }
      if (actualAtt.attributes.data_type === 'date') {
        value = moment(value).format('dddd MMM Do YYYY').toString();
      }
    }
    if (!actualAtt) {
      return null;
    }
    return (
      <div className='customAttribute' key={Math.random()}>
        <p style={customStyles}>{actualAtt?.attributes.label}</p>
        <span dangerouslySetInnerHTML={{ __html: value }}></span>
      </div>
    );
  }
  return null;
};

export const formatComment = (comment: string | undefined): JSX.Element | null => {
  if (!comment) {
    return null;
  } else if (comment.includes('<')) {
    return <p className='comment' dangerouslySetInnerHTML={{ __html: comment }}></p>;
  } else {
    return <p className='comment'>{comment}</p>;
  }
};

export const buildNewUpdate = (
  updatedState: Attributes,
  reference: string,
  e: React.ChangeEvent | string,
  appearance: string,
): Attributes => {
  const nuevo = { ...updatedState };
  const attributes = Object.assign({}, updatedState.custom_attributes);
  if (nuevo.custom_attributes && reference) {
    if (typeof e === 'string') {
      attributes[reference as string] = e as string;
      nuevo.custom_attributes = attributes;
      return nuevo;
    }
    const target = e.target as HTMLInputElement;
    if (appearance === 'checkbox') {
      if (reference in nuevo.custom_attributes) {
        const selectedsArray = [...(attributes[reference as string] as string[])];
        const index = selectedsArray.indexOf(target.value);
        index === -1 ? selectedsArray.push(target.value) : selectedsArray.splice(index, 1);
        attributes[reference as string] = selectedsArray;
      } else {
        attributes[reference as string] = [target.value];
      }
    } else if (appearance === 'radio') {
      attributes[reference as string] = [target.value];
    } else if (appearance === 'multiselect') {
      const selectTarget = e.target as HTMLSelectElement;
      const values = [];
      for (let i = 0; i < selectTarget.selectedOptions.length; i++) {
        values.push(selectTarget.selectedOptions[i].value);
      }
      attributes[reference as string] = values;
    } else {
      attributes[reference as string] = target.value;
    }
  }
  nuevo.custom_attributes = attributes;
  return nuevo;
};

export const getInitialCustomAttributes = (inputsData: CustomAttribute[]): { [key: string]: string | string[] } => {
  const customAttributes: { [key: string]: string | string[] } = {};
  inputsData.forEach(inpData => {
    if (inpData.attributes.reference) {
      const settings = inpData.attributes.settings && JSON.parse(inpData.attributes.settings);
      if ('formula' in settings) {
        customAttributes[inpData.attributes.reference as string] = eval(settings.formula);
      } else {
        customAttributes[inpData.attributes.reference as string] = settings.defaultValue;
      }
    }
  });
  return customAttributes;
};

export const getNewUpdateAttributes = (
  editingItem: update | null,
  newUpdate: Attributes,
  initialCustomAttributes: { [key: string]: string | string[] },
): Attributes => {
  if (editingItem?.attributes) {
    const mergedData = Object.assign(newUpdate, editingItem.attributes);
    return mergedData;
  } else {
    return {
      ...newUpdate,
      custom_attributes: initialCustomAttributes,
    };
  }
};

/* eslint-disable no-param-reassign */
import { convertObjectToBase64 } from 'helper/common';
import * as criteria from 'helper/criteria';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { FetchGoalsByPlanProps, FetchGoalsResponse } from 'api/type';
import { fetchGoalsByPlanApi } from 'api/plan';
import { PlanState } from 'reducers/planner-slice/type';
import { StatusCode } from 'type/error';

import { selectRole } from 'reducers/auth-slice/selector';
import { RootState } from 'reducers/rootReducer';

export const fetchGoalsByPlanThunk = createAsyncThunk<
  FetchGoalsResponse,
  FetchGoalsByPlanProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('plan/fetchGoalsByPlan', async (option, { getState }) => {
  try {
    const state: RootState = getState();
    const role = selectRole(state);

    const defaultFilter = criteria.build(
      criteria.operator.AND,
      criteria.build(
        criteria.operator.OR,
        {
          field: 'collaborators',
          operator: criteria.operator.GREATHER_THAN,
          value: 0,
          criteria: {
            field: 'id',
            operator: criteria.operator.EQUAL,
            value: role?.id,
          },
        },
        {
          field: 'role',
          operator: criteria.operator.EQUAL,
          value: role?.id,
        },
      ),
      {
        field: 'status',
        operator: criteria.operator.EQUAL,
        value: 'APPRO',
      },
    );
    let filter = defaultFilter;

    if (option.decodedFilter) {
      filter = criteria.build(criteria.operator.AND, defaultFilter, option.decodedFilter);
    }
    const encodedFilter = convertObjectToBase64(filter);
    const response = await fetchGoalsByPlanApi({
      ...option,
      filter: encodedFilter,
    });
    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchGoalsByPlanReducer = (builder: ActionReducerMapBuilder<PlanState>): void => {
  builder.addCase(fetchGoalsByPlanThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.goalsByPlan = payload;
  });
  builder.addCase(fetchGoalsByPlanThunk.pending, state => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchGoalsByPlanThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

import React, { useState } from 'react';
import bn from 'helper/bemnames';
import { ChevronDownIcon, ChevronEndIcon, Flex } from '@fluentui/react-northstar';

interface Props {
  title?: string;
  content: React.ReactElement;
}

const bem = bn.create('focus-area');

const FocusArea: React.FC<Props> = ({ title, content }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <div onClick={() => setExpanded(!expanded)}>
        <Flex gap='gap.small' vAlign='center' className={bem.e('title')}>
          <span>{title}</span>
          {expanded ? <ChevronDownIcon size='small' /> : <ChevronEndIcon size='small' />}
        </Flex>
      </div>
      <div className={`${bem.e('content')} ${expanded ? bem.m('show') : bem.m('hidden')}`}>{content}</div>
    </>
  );
};

export default FocusArea;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { prepareAuthorizationToken } from 'helper/string';
import { authAction } from 'reducers/auth-slice';
import { getRefreshTokenThunk } from 'reducers/auth-slice/reducers/getRefreshTokenReducer';
import { AppStore } from 'reducers/store';
import { StatusCode } from 'type/error';
import { RequestError } from './type';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pMemoize = require('p-memoize');
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const apiClient = axios.create({
  timeout: 30000,
  headers,
});
export { apiClient };
export default {
  setup: (store: AppStore): void => {
    apiClient?.interceptors.request.use(
      async request => {
        return request;
      },
      error => {
        const err: RequestError = error;
        Promise.reject(err);
      },
    );

    const getRefreshTokenMemoized = pMemoize(() => store.dispatch(getRefreshTokenThunk()).then(unwrapResult), {
      maxAge: 60 * 1000,
    });

    apiClient?.interceptors.response.use(
      response => {
        return response;
      },

      async error => {
        const message = error?.response?.data?.errors?.message;
        const statusCode = error?.response?.status;
        const err: RequestError = {
          statusCode,
          message,
        };

        const originalRequest = error.config;

        if (statusCode === StatusCode.Unauthorized && !originalRequest._retry) {
          originalRequest._retry = true;
          const tokenState = await getRefreshTokenMemoized();
          apiClient.defaults.headers.common['Authorization'] = originalRequest.headers['Authorization'] = prepareAuthorizationToken(
            tokenState.accessToken,
            tokenState.tokenType,
          );
          return apiClient(originalRequest);
        }

        if (statusCode === StatusCode.Unauthorized && originalRequest._retry) {
          store.dispatch(authAction.signOutUser(undefined));
        }

        return Promise.reject(err);
      },
    );
  },
};

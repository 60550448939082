export interface ValidationErrors {
  errorMessage: string;

  fieldErrors: Record<string, string>;
}
export enum StatusCode {
  NotFound = 404,
  Offline = -1,
  Unauthorized = 401,
}

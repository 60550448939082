import React from 'react';
import moment from 'moment';
import Status, { CLASS_COLORS } from 'components/goals/status/Status';
import Type from 'components/goals/type/Type';
import Owner from 'components/goals/owner/Owner';
import { Goal, GoalProgressStatus, GoalType } from 'reducers/goal-slice/type';
import bn from 'helper/bemnames';
import classNames from 'classnames';
import { ActionMode } from 'components/forms/goal/type';
import { path } from 'ramda';
import { getManualStatus } from 'helper/goal';

const bem = bn.create('goals');
interface GoalRowProps {
  goals: Goal[];
  setFormAction: (action: React.SetStateAction<ActionMode>) => void;
  fetchGoal: (id: string | number) => void;
}
const GoalRows = (
  props: GoalRowProps,
): {
  key: number;
  className: string;
  onClick: () => void;
}[] => {
  const { goals, fetchGoal, setFormAction } = props;
  return goals.map(({ attributes, relationships, id }: Goal, index: number) => {
    const dueDate = moment(attributes.end_time).format('MMM DD, YYYY');
    const owners = Array.isArray(relationships.owners?.data) ? relationships.owners?.data : [];
    const isManualStatus = attributes.settings && attributes.settings?.tracking.manualStatus;
    const status = (isManualStatus ? getManualStatus(attributes) : attributes.progress_status) as GoalProgressStatus;
    const items = [
      {
        key: `${index}-title`,
        content: <span className={bem.e('title')}>{attributes.title}</span>,
        className: bem.e('width-title'),
        truncateContent: true,
      },
      {
        key: `${index}-type`,
        content: <Type type={path(['entityTemplate', 'data', 'attributes', 'name'], relationships) as GoalType} />,
        className: bem.e('width-type'),
      },
      {
        key: `${index}-owner`,
        'data-testid': `${index}-owner`,
        content: owners.length > 0 ? <Owner owner={owners[0]} /> : <span />,
        className: bem.e('width-owner'),
      },
      {
        key: `${index}-date`,
        content: <span className={bem.e('text')}>{dueDate}</span>,
        className: bem.e('width-date'),
      },
      {
        key: `${index}-status`,
        content: <Status status={status} />,
        className: bem.e('width-status'),
      },
      {
        key: `${index}-weight`,
        content: <span className={bem.e('text')}>{path(['weight', 'data', 'attributes', 'name'], relationships) as GoalType}</span>,
        className: bem.e('width-weight'),
      },
    ];
    return {
      key: index,
      className: classNames(bem.e('goal-row'), bem.e(CLASS_COLORS[status])),
      items,
      'data-testid': `goal-row-${index}`,
      onClick: () => {
        setFormAction(ActionMode.Edit);
        fetchGoal(id);
      },
    };
  });
};
export default GoalRows;

import { createSlice } from '@reduxjs/toolkit';

import { signInReducer } from './reducers/signInUserReducer';
import { signOutReducer } from './reducers/signOutUserReducer';
import { companyReducer } from './reducers/companyUserReducer';

import { AuthState } from './type';
import { getRefreshTokenReducers } from './reducers/getRefreshTokenReducer';
import { fetchProfileReducer } from './reducers/fetchProfileReducer';

const initialState: AuthState = { isLoading: false, instanceName: '', instanceRegion: '', user: undefined, leadId: '' };

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOutUser: signOutReducer,
    companyUser: companyReducer,
    signInUser: signInReducer,
  },
  extraReducers: builder => {
    getRefreshTokenReducers(builder);
    fetchProfileReducer(builder);
  },
});
export { reducer, actions as authAction };

import React, { SetStateAction } from 'react';
import bn from 'helper/bemnames';
import GoalsList from '../goals-list/GoalsList';
import { ActionMode } from 'components/forms/goal/type';

interface Props {
  setIsOpenFormModal: (value: boolean) => void;
  setFormAction: (mode: SetStateAction<ActionMode>) => void;
  handleSort: (field: string, join: string | null | undefined) => void;
  sort: string;
}

const bem = bn.create('plan');

const PlanView: React.FC<Props> = ({ setIsOpenFormModal, setFormAction, handleSort, sort }) => {
  return (
    <div className={bem.b()}>
      <GoalsList setIsOpenFormModal={setIsOpenFormModal} setFormAction={setFormAction} handleSort={handleSort} sort={sort} />
    </div>
  );
};

export default PlanView;

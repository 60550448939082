import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducers/store';
import { selectIsAuthenticated, selectAuth } from 'reducers/auth-slice/selector';
import { routes } from 'journey/navigation/Routes';
import { fetchProfileThunk } from 'reducers/auth-slice/reducers/fetchProfileReducer';
import { apiClient } from 'api/client';
import { prepareAPIBaseUrl, prepareAuthorizationToken } from 'helper/string';

interface Props extends RouteProps {
  // eslint-disable-next-line
  component: any;
}
const PrivateRoute: React.FunctionComponent<Props> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const { tokenState, instanceName, instanceRegion } = useAppSelector(selectAuth);

  if (isAuthenticated && tokenState) {
    apiClient.defaults.headers.common['Authorization'] = prepareAuthorizationToken(tokenState.accessToken, tokenState.tokenType);
    apiClient.defaults.baseURL = prepareAPIBaseUrl(instanceName, instanceRegion);
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfileThunk()); // re-fetch user information
    }
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /sign-in page
    <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to={routes.WELCOME} />)} />
  );
};

export default PrivateRoute;

import moment from 'moment';
import React from 'react';
import { Template, CustomAttribute } from 'reducers/template-slice/type';
import { Included, update } from 'reducers/update-slice/type';
import { User } from 'reducers/user-slice/type';
import FeedListItem from './FeedListItem';

interface FeedList {
  content: update[] | undefined;
  onStartEdit?: (item: update, user: User) => void;
  included?: Included[];
  attributes?: CustomAttribute[] | [];
  templates?: Template[];
  instanceName: string;
  goalId: string;
}

interface groupedContent {
  [key: string]: update[];
}

const FeedList: React.FC<FeedList> = props => {
  const groupedContent: groupedContent | undefined = {};

  props.content?.forEach(item => {
    const createdAt = moment(item.attributes.created_at).format('dddd MMM Do YYYY');
    if (createdAt in groupedContent) {
      groupedContent[createdAt].push(item);
    } else {
      groupedContent[createdAt] = [item];
    }
  });

  return (
    <div className='container'>
      {Object.entries(groupedContent).map(entry => {
        return (
          <FeedListItem
            key={entry[0]}
            items={entry[1]}
            included={props.included}
            attributes={props.attributes}
            templates={props.templates}
            instanceName={props.instanceName}
            onStartEdit={props.onStartEdit}
            goalId={props.goalId}
          ></FeedListItem>
        );
      })}
    </div>
  );
};

export default FeedList;

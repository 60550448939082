import { apiClient } from './client';
import { FetchPlansProps, FetchPlansResponse, FetchPlanResponse, FetchPlanProps, FetchGoalsByPlanProps, FetchGoalsResponse } from './type';

export const fetchPlansApi = async ({ page, keyword, sort, size, filter, include }: FetchPlansProps): Promise<FetchPlansResponse> => {
  const url = '/plans';

  const { data } = await apiClient.get(url, { params: { 'page[page]': page, keyword, sort, 'page[size]': size, filter, include } });
  return data;
};

export const fetchPlanApi = async ({ id }: FetchPlanProps): Promise<FetchPlanResponse> => {
  const url = `/plans/${id}`;

  const { data } = await apiClient.get<FetchPlanResponse>(url);
  return data;
};

export const fetchGoalsByPlanApi = async ({ id, filter, sort }: FetchGoalsByPlanProps): Promise<FetchGoalsResponse> => {
  const url = `/plans/${id}/goals`;

  let params: { include: string; 'fields[goal]': string; filter?: string; sort: string | null | undefined } = {
    include: 'owners.user,aligned_parent_goals_appro.id',
    sort,
    'fields[goal]':
      'settings,description,end_time,initial,progress,progress_percentage,progress_status,start_time,status,target,title,owners,aligned_focus_areas,aligned_parent_goals_appro,entityTemplate,unit,weight,custom_attributes,updates&include=aligned_parent_goals_appro.id',
  };

  if (filter) {
    params = { ...params, filter };
  }

  const { data } = await apiClient.get(url, { params });

  return data;
};

export default { fetchPlansApi, fetchPlanApi, fetchGoalsByPlanApi };

import React from 'react';
import { GoalProgressStatus } from 'reducers/goal-slice/type';
import bn from 'helper/bemnames';
import Tooltip from 'components/tooltip/Tooltip';
import classNames from 'classnames';
import { ArrowDownIcon, ReloadIcon } from 'components/core/icons/Icons';
const bem = bn.create('goal-status');
interface Props {
  status: GoalProgressStatus;
  isTooltip?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  pointer?: boolean;
}
export const CLASS_COLORS = {
  'NOT-STARTED': 'not-start',
  OVERDUE: 'overdue',
  'ON-TRACK': 'on-track',
  BEHIND: 'behind',
  COMPLETE: 'complete',
  'WITHIN-THRESHOLD': 'within-threshold',
  MANUAL: 'manual',
};
const TITLES = {
  'NOT-STARTED': 'Not Started',
  OVERDUE: 'Overdue',
  'ON-TRACK': 'On track',
  BEHIND: 'Behind',
  COMPLETE: 'Completed',
  'WITHIN-THRESHOLD': 'Within threshold',
  MANUAL: 'Manual',
};
const Status: React.FunctionComponent<Props> = ({ pointer, onClick, status, isTooltip }: Props) => {
  const content = (
    <div onClick={onClick} className={classNames(pointer ? bem.e('with-pointer') : null, bem.b(), bem.m(CLASS_COLORS[status]))}>
      {pointer && (
        <i className={bem.e('before-icon')}>
          <ReloadIcon />
        </i>
      )}
      {TITLES[status]}
      {pointer && (
        <i className={bem.e('after-icon')}>
          <ArrowDownIcon />
        </i>
      )}
    </div>
  );
  if (isTooltip) {
    return (
      <Tooltip
        trigger={content}
        message={
          <span>
            The status of this goal is being set manually, independent of progress. <br />
            Open the goal in Cascade to view details.
          </span>
        }
      />
    );
  }
  return content;
};
export default Status;

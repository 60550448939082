import React from 'react';
import { update, Included } from 'reducers/update-slice/type';
import moment from 'moment';
import { Template, CustomAttribute } from 'reducers/template-slice/type';
import { getTemplateName, getCustomAttributes, formatComment } from '../../../helper/feed';
import { EditIcon } from 'components/core/icons/Icons';
import { useAppSelector } from 'reducers/store';
import { User } from 'reducers/user-slice/type';
import { selectUser } from 'reducers/auth-slice/selector';
import FeedFileLink from './FeedFileLink';

interface FeedListItem {
  items: update[];
  onStartEdit: (item: update, user: User) => void;
  included?: Included[];
  attributes?: CustomAttribute[] | [];
  templates?: Template[];
  instanceName: string;
  goalId: string;
}

export const FeedListItem: React.FC<FeedListItem> = props => {
  const user = useAppSelector<User | undefined>(selectUser);
  const getFullName = (id: string | number) => {
    if (props.included) {
      const author = props.included.find(u => u.id === id && u.type === 'user');
      const firstName = author?.attributes.first_name;
      const lastName = author?.attributes.last_name?.charAt(0);
      return `${firstName} ${lastName}.`;
    }
  };

  const editHandler = (item: update) => {
    props.onStartEdit(item, user as User);
  };

  return (
    <div className='listitem'>
      {props.items.map((item: update) => {
        if (item.attributes.type !== 'CREATED') {
          return (
            <div key={item.id} className='single-update'>
              <span className='date'>
                {moment().isSame(moment(item.attributes.created_at), 'day')
                  ? 'Today'
                  : moment(item.attributes.created_at).format('dddd MMM Do YYYY')}
              </span>
              <div className='content'>
                <div>
                  <p className='name'>
                    {getFullName(item.relationships.user.data.id)} |
                    {item.relationships.entityTemplate.data ? (
                      <span className='template'>{getTemplateName(item.relationships.entityTemplate.data.id, props.templates)}</span>
                    ) : null}
                  </p>
                  {item.attributes.deleted ? (
                    <p className='comment'>This update has been deleted</p>
                  ) : (
                    <>
                      {formatComment(item.attributes.comment)}
                      {item.attributes.custom_attributes && Object.keys(item.attributes.custom_attributes).length > 0
                        ? Object.entries(item.attributes.custom_attributes).map(item => {
                            return getCustomAttributes(item as [string, string], props.attributes);
                          })
                        : null}
                      {item.relationships.file.data ? (
                        <FeedFileLink included={props.included} instanceName={props.instanceName} item={item} />
                      ) : null}
                    </>
                  )}
                </div>
                <div className='sideBox'>
                  <p className='time'>{moment(item.attributes.created_at).format('h:mm a')}</p>
                  <div className='editIcon' onClick={() => editHandler(item)}>
                    <EditIcon />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default FeedListItem;

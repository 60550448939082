import React from 'react';
import { Dialog, DialogProps } from '@fluentui/react-northstar';
import bn from 'helper/bemnames';
import classNames from 'classnames';
const bem = bn.create('modal');
export type BaseModalProps = DialogProps;

const BaseModal: React.FC<BaseModalProps> = (props: BaseModalProps) => {
  const { className, ...rest } = props;
  return <Dialog className={classNames(bem.b([className]))} {...rest}></Dialog>;
};
export default BaseModal;

import { AuthState } from 'reducers/auth-slice/type';
import { PayloadAction } from '@reduxjs/toolkit';
import { prepareAPIBaseUrl } from 'helper/string';
import { apiClient } from 'api/client';

export function companyReducer(
  state: AuthState,
  action: PayloadAction<{
    instanceName: string | undefined;
    instanceRegion: string | undefined;
    leadId: string | undefined;
  }>,
): void {
  if (action.payload.instanceName) {
    state.instanceName = action.payload.instanceName;
    apiClient.defaults.baseURL = prepareAPIBaseUrl(action.payload.instanceName, action.payload.instanceRegion);
  }
  if (action.payload.instanceRegion) {
    state.instanceRegion = action.payload.instanceRegion;
  }
  if (action.payload.leadId) {
    state.leadId = action.payload.leadId;
  }
}

import { getAttachedFile } from 'helper/feed';
import { update, Included } from 'reducers/update-slice/type';
import { AppDispatch, useAppDispatch } from 'reducers/store';

import React, { useEffect, useState } from 'react';

interface FeedFileLink {
  included: Included[] | undefined;
  instanceName: string;
  item: update;
}

const FeedFileLink: React.FC<FeedFileLink> = props => {
  const dispatch: AppDispatch = useAppDispatch();
  const [link, setLink] = useState<JSX.Element | null | undefined>();

  useEffect(() => {
    getAttachedFile(props.included, props.instanceName, props.item.relationships.file, dispatch).then(rta => {
      setLink(rta);
    });
  }, []);

  return <span>{link}</span>;
};

export default FeedFileLink;

import { apiClient } from './client';
import { FetchConfigResponse, FetchConfigProps } from './type';

export const fetchConfigApi = async ({ keyword, sort, filter, include }: FetchConfigProps): Promise<FetchConfigResponse> => {
  const url = '/config';

  const { data } = await apiClient.get(url, { params: { keyword, sort, filter, include } });
  return data;
};

export default { fetchConfigApi };

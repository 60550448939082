import React from 'react';
import ReactQuill from 'react-quill';
import { CustomAttribute, CustomTemplate, EntityTemplateSettings } from 'reducers/template-slice/type';
import { Attributes } from 'reducers/update-slice/type';
import { User, UserIncluded } from 'reducers/user-slice/type';
import Input from '../components/forms/update/Input';

const constructElement = (
  element: CustomAttribute,
  value: string,
  changeHandler: (e: string | React.ChangeEvent, reference: string | undefined, appearance?: string) => void,
) => {
  return <Input element={element} value={value} changeHandler={changeHandler} />;
};

export const buildElement = (
  element: CustomAttribute,
  changeHandler: (e: string | React.ChangeEvent, reference: string | undefined) => void,
  updateState: Attributes,
): JSX.Element | null => {
  const settings = element.attributes.settings && JSON.parse(element.attributes.settings);
  const value = updateState.custom_attributes && updateState.custom_attributes[element.attributes.reference as string];
  const input = constructElement(element, value as string, changeHandler);
  return (
    <div key={element.id} className={['input-container', element.attributes.appearance].join(' ')}>
      <p style={{ color: settings.color }}>{element.attributes.label}</p>
      {input}
    </div>
  );
};

export const buildDescriptionBox = (
  onChangeHandler: (e: React.ChangeEvent | string, reference: string | undefined) => void,
  newUpdate: Attributes,
): JSX.Element | null => {
  return (
    <div>
      <p>Description</p>
      <ReactQuill onChange={e => onChangeHandler(e, 'comment')} value={newUpdate ? newUpdate.comment : ''} />
    </div>
  );
};

export const getInputs = (selectedUpdate: CustomTemplate, attributes: CustomAttribute[]): CustomAttribute[] => {
  const entTmpAtts: CustomAttribute[] = [];
  selectedUpdate.relationships.entityTemplateAttributes.data.forEach(ents => {
    const entAmpAtt = attributes.find(eta => eta.id === ents.id && eta.type === 'entityTemplateAttribute');
    if (entAmpAtt) entTmpAtts.push(entAmpAtt);
  });
  const attrInputs: CustomAttribute[] = [];
  entTmpAtts.forEach(ent => {
    const att = attributes.find(attr => +attr.id === ent.attributes.attribute_id && attr.type === 'attribute');
    if (att) attrInputs.push(att);
  });
  return attrInputs;
};

export const filterRequiredCustomAtts = (
  customAtts: { [key: string]: string | string[] },
  inputsData: CustomAttribute[],
): { [key: string]: boolean } => {
  const filteredAtts: { [key: string]: boolean } = {};
  for (const key in customAtts) {
    inputsData.forEach(inp => {
      if (inp.attributes.reference === key) {
        const settings = JSON.parse(inp.attributes.settings as string);
        settings.isRequired === true ? (filteredAtts[key] = false) : null;
        inp.attributes.appearance === 'formula' ||
        inp.attributes.appearance === 'radio' ||
        inp.attributes.appearance === 'checkbox' ||
        inp.attributes.appearance === 'dropdown'
          ? (filteredAtts[key] = true)
          : null;
        settings.isRequired === true && customAtts[key] !== null ? (filteredAtts[key] = true) : null;
      }
    });
  }
  return filteredAtts;
};

export const validateCustomAttribute = (
  reference: string,
  validations: { [key: string]: boolean },
  updatedState: Attributes,
): { [key: string]: boolean } | void => {
  if (updatedState.custom_attributes) {
    const updatedValidations = {
      ...validations,
      [reference]:
        (updatedState.custom_attributes[reference] === '<p><br></p>' ? false : true) &&
        updatedState.custom_attributes[reference].length > 0,
    };
    return updatedValidations;
  }
};

export const canAccessTemplate = (user: User, settings: EntityTemplateSettings, userIncluded: UserIncluded[]): boolean => {
  const role = userIncluded.find(inc => inc.type === 'role' && inc.id === user.relationships.role.data?.id);
  const filteredPrivileges: (UserIncluded | undefined)[] = [];
  switch (settings.restriction.restrictedTo) {
    case 'ALL_USERS':
      return true;
    case 'MANAGERS':
      if (role?.attributes) {
        return role?.attributes?.rgt && role?.attributes?.lft ? role?.attributes?.rgt - role?.attributes?.lft > 1 : false;
      } else {
        return false;
      }
    case 'USER_TEAM':
      return settings.restriction.selectedUserIDs.includes(+user.id);
    case 'PRIVILEGE':
      role?.relationships?.privileges &&
        role?.relationships?.privileges.data.forEach(priv => {
          const filteredPriv = userIncluded.find(inc => inc.type === 'privilege' && inc.id === priv.id);
          filteredPrivileges.push(filteredPriv);
        });
      return filteredPrivileges.some(priv =>
        priv?.attributes ? settings.restriction.selectedPrivileges.includes(priv?.attributes.privilege as string) : false,
      );
    case 'ORGANISATIONAL_UNIT':
      return role?.attributes?.org_unit_id ? settings.restriction.selectedOrgUnitIDs.includes(role?.attributes?.org_unit_id) : false;
    case 'SPECIFIC_PEOPLE':
      return settings.restriction.selectedUserIDs.includes(+user.id);
    default:
      return true;
  }
};

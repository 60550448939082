import React, { useState } from 'react';
import bn from 'helper/bemnames';
import { Flex } from '@fluentui/react-northstar';
import { Logo } from 'components/core/icons/Icons';
import Button from 'components/core/button/Button';
import { useHistory } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { TokenState } from 'reducers/auth-slice/type';
import { formatTokenState } from 'helper/common';
import { authAction } from 'reducers/auth-slice';
import { useAppDispatch } from 'reducers/store';
import { routes } from 'journey/navigation/Routes';
import { segmentAnalyticsThunk } from 'reducers/auth-slice/reducers/segmentAnalyticsReducer';
import { fetchProfileThunk } from 'reducers/auth-slice/reducers/fetchProfileReducer';
import { unwrapResult } from '@reduxjs/toolkit';

const bem = bn.create('welcome-screen');

const WelcomeScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const failedToLogin = (): void => {
    setLoading(false);
    dispatch(authAction.signOutUser(undefined));
  };

  const openLogin = (): void => {
    setLoading(true);
    //TODO: Convert callback to promise, for more info, please refer to https://aka.ms/teamsfx-callback-to-promise.
    microsoftTeams.authentication
      .authenticate({
        url: `${window.location.origin}/bot-auth-popup`,
        width: 700,
        height: 700,
      })
      .then((result: unknown): void => {
        const tokenState: TokenState = formatTokenState(result);
        const instanceName = tokenState?.instance || '';
        dispatch(authAction.companyUser({ instanceName, instanceRegion: tokenState.instanceRegion, leadId: tokenState.leadId }));
        dispatch(authAction.signInUser(tokenState));
        // re-fetch user information
        dispatch(fetchProfileThunk())
          .then(unwrapResult)
          .then(() => {
            dispatch(
              segmentAnalyticsThunk({
                event: 'User Login',
                options: {},
                isSystem: true,
                action: 'login',
              }),
            );
            setLoading(false);
            history.push(routes.TAB);
          })
          .catch(failedToLogin);
      })
      .catch(failedToLogin);
  };

  return (
    <div className={bem.b()}>
      <Flex column hAlign='center'>
        <div className={bem.e('logo')}>
          <Logo />
        </div>
        <h1 className={bem.e('title')}>Welcome to Cascade!</h1>
        <p className={bem.e('subtitle')}>Please sign in to continue.</p>
        <p className={bem.e('sign-up-text')}>
          Don&apos;t have a Cascade account yet?{' '}
          <a href='https://www.cascade.app/trial' target='__blank'>
            Click here
          </a>{' '}
          to sign up
        </p>
        <Button
          className={bem.e('btn-sign-in')}
          data-testid='welcome-btn-sign-in'
          disabled={loading}
          onClick={openLogin}
          content={'Sign in'}
          primary
          size='medium'
        />
      </Flex>
    </div>
  );
};
export default WelcomeScreen;

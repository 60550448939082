import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { FetchUpdatesProps, FetchUpdatesResponse } from '../type';
import { fetchUpdatesApi } from 'api/update';
import { PAGE_SIZE_NUMBER } from 'config/api';
import { StatusCode } from 'type/error';
import { convertObjectToBase64 } from 'helper/common';
import { RootState } from 'reducers/rootReducer';
import { UpdateState } from '../type';

export const fetchUpdatesThunk = createAsyncThunk<
  FetchUpdatesResponse,
  FetchUpdatesProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('update/fetchUpdates', async option => {
  try {
    const updateValues = option.goal?.relationships.updates.data?.map(upd => upd.id);
    const filter = convertObjectToBase64({
      field: 'id',
      operator: 'in',
      value: updateValues,
    });
    const response = await fetchUpdatesApi({ ...option, sort: '-created_at', size: PAGE_SIZE_NUMBER, filter });
    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchUpdatesReducer = (builder: ActionReducerMapBuilder<UpdateState>): void => {
  builder.addCase(fetchUpdatesThunk.fulfilled, (state, { payload }) => {
    state.error = undefined;
    state.isLoading = false;
    if (payload.meta.pagination.current_page === 1) {
      state.updates = payload.data;
    } else {
      state.updates = state.updates.concat(payload.data);
    }
    state.pagination = payload.meta.pagination;
    state.included = payload.included;
  });
  builder.addCase(fetchUpdatesThunk.pending, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(fetchUpdatesThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};

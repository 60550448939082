import React from 'react';
import { Loader as LoaderBase, LoaderProps } from '@fluentui/react-northstar';
import bn from 'helper/bemnames';
import classNames from 'classnames';
const bem = bn.create('loader');
interface Props extends LoaderProps {
  loading?: boolean;
  fluid?: boolean;
  label?: string;
}

const Loader: React.FC<Props> = (props: Props) => {
  const { loading, fluid, ...rest } = props;
  if (!loading) return null;
  return (
    <>
      <LoaderBase
        className={classNames(bem.b(), {
          [bem.m('fluid')]: fluid,
        })}
        {...rest}
      />
    </>
  );
};
export default Loader;

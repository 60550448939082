import React, { useState } from 'react';
import SignInModal from 'components/modals/sign-in/SignIn';
import bn from 'helper/bemnames';
import { prepareAuthUri } from 'helper/string';
import Loader from 'components/core/loader/Loader';
import { fetchInstanceRegion } from 'api/instance';
const bem = bn.create('bot-auth-popup');

interface Props {
  title?: string;
}
const BotAuthPopup: React.FC<Props> = () => {
  const [instanceName, setInstanceName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const onConfirm = (): void => {
    setLoading(true);
    fetchInstanceRegion(instanceName)
      .then(() => {
        setLoading(false);
        window.location.assign(prepareAuthUri(instanceName));
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Instance does not exist.');
      });
  };

  return (
    <div className={bem.b()}>
      <SignInModal
        backdrop={false}
        className={bem.e('sign-in-modal')}
        open={true}
        instanceName={instanceName}
        setInstanceName={setInstanceName}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        onConfirm={onConfirm}
      />
      <Loader loading={loading} fluid />
    </div>
  );
};

export default BotAuthPopup;

import { GoalAttribute, GoalProgressStatus } from './../reducers/goal-slice/type';

type manualStatusTransformMap = {
  key: string;
  value: GoalProgressStatus;
};
export const manualStatusTranformMap: Array<manualStatusTransformMap> = [
  {
    key: 'id_1620610225905_2',
    value: GoalProgressStatus.ON_TRACK,
  },
  {
    key: 'id_1620610225905_1',
    value: GoalProgressStatus.NOT_STARTED,
  },
  {
    key: 'I1620610232976',
    value: GoalProgressStatus.BEHIND,
  },
  {
    key: 'I1620610265915',
    value: GoalProgressStatus.OVERDUE,
  },
  {
    key: 'I1620610266658',
    value: GoalProgressStatus.COMPLETE,
  },
];
export const getManualStatusByKey = (key: string): GoalProgressStatus => {
  const manualStatus = manualStatusTranformMap.filter(status => status.key == key)[0].value;
  return manualStatus;
};
export const getManualStatus = (attributes: GoalAttribute | undefined): GoalProgressStatus => {
  if (attributes?.custom_attributes?.RESERVED_SYSTEM_STATUS != undefined) {
    const key = attributes?.custom_attributes?.RESERVED_SYSTEM_STATUS[0];
    const manualStatus = manualStatusTranformMap.filter(status => status.key == key)[0].value;
    return manualStatus;
  } else {
    return GoalProgressStatus.MANUAL;
  }
};
